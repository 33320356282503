import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Rating from "react-rating";

export const Testimonials = () => {
  const slideData = [
    {
      title: "Great visioner",
      content:
        "I purchased a full Numerology report a few days ago. I am still digesting all the information in the emailed report, but, after the zoom discussion, I was hooked. I would say that the report, and Parth’s explanations, read like a book of my life to this point. And, with so much added info in the Report, I am more certain of the path I am on and more hopeful for my future and that I will attain my target. Thank you, parth, for this wonderful experience.”",
      userName: "Pusapak chuhan",
      occupation: "Working professional",
    },
    {
      title: "Great question solver",
      content:
        "Hello parth, Thank you so much for the report and non expensive remedies. I like that you use both systems, very helpful. I will encourage everybody to consult you because of the insights and helpful suggestion and that you always got back to me whenever I needed help or answer to my endless Question. very impressive and great work. All the best to you.",
      userName: "Pratap Mistry",
      occupation: "Business man",
    },
    {
      title: "Good Career helper",
      content: (
        <>
          Hi guys, I was studding in diploma mechanical engineering. I had
          plenty of back clocks in my first year then I booked live appointment
          of MR. Parth patel. He provided proper remedies to me. I used in my
          life. My life drastically changes. I had passed my exam with flying
          colors. I got admission in reputed College of engineering. It was
          miracle in my life.
          <br />
          Thanks team numerolution.com
        </>
      ),
      userName: "Mr.Sahil karodiya",
      occupation: "Student",
    },
    {
      title: "Got job with help numerolution",
      content:
        "Hello numerolution.com team I have done completed Jet air academy course. Our training placement officer placed in F.M.C.G Company.Moreover, I could not find out job in my field. I used nymerolution side. I Purchased report. I contact to Numerolution.com. He provides proper solution. Nowadays, I am working in Air India.Thank you for the remedies you gave me to overcome the absence of some of my birth date numbers.I seriously noticed a remarkable difference in my energy and overall outlook.",
      userName: "Mr. Nimesh Sanghani",
      occupation: "Working professional (Air India)",
    },
    {
      title: "Great live saver",
      content:
        "I am so glad to have found this precious information on numerology. I was upset; everything I did in my life was ending up incomplete/failed. I was crazy and frustrated but much thanks to Jay his precious analysis has helped me to get back my life and understand the missing elements for my success. Once again thank u Jay.",
      userName: "Niki Patel",
      occupation: "Student",
    },
    {
      title: "Significant Vaastu solution provide",
      content: (
        <p>
          Hello Guys,&nbsp;Today I want to tell you very honestly that this
          website is providing genuine inputs about your life through
          numerology, I have vastu related problems. Some months ago, I had
          confused about vastu.I used{" "}
          <a href={window.location.href}>{window.location.host}</a> Website. It
          provided proper guidance and it works so accurately that I also told
          about this to my friend and she also shocked to know how accurate the
          report is, they also provide solutions which can be considered very
          easily.
          <br />
          Thanks and regards
        </p>
      ),
      userName: "Miss Sharuti barot",
      occupation: "Actress of gujarati movie (Shu thayu)",
    },
    {
      title: "Helpfull for my family",
      content: (
        <p>
          Thank you so much for the numerology report, it is great value for
          money and I love that you include both systems of numerology.The
          extensive first name list has been very helpful and by reading the
          report I now have the tools to work out, on my own, any name that is
          right for me (I’m not just restricted to the names on the list).Will
          be returning soon to have my wife reports done!
          <br />
          kind regards
        </p>
      ),
      userName: "Heta Patel",
      occupation: "Journalist ",
    },
    {
      title: "Good life guide",
      content:
        "Thank you very much for the Numerology report. After Reading numerology report, I purchased remedies. It was well worth the money, not just for the “numbers” but for your enthusiasm and practical help in seeing me live life outside the box, taking a chance to express my individuality and unique creativity. Thank you for giving/sharing everything you could with me in the time we had.”",
      userName: "Jaimini Panchal",
      occupation: "Working professional",
    },
    {
      title: "Spiffing Career guidance",
      content: (
        <p>
          Hi Team, I have dilemma about master. What should I do after my
          graduation? I download report. Then, I book appointments. He provided
          guidance according my birthday, which course shoots my personality.
          Now, I am pursuing Master of Science in Well know institutes. I
          thanking you a lot for identifying my life’s issues and provide me non
          expensive solutions, it works and rocks at the same time…
          <br />
          Thanks a lot
        </p>
      ),
      userName: "Mrs. Disha hardik kachhadiya",
      occupation: "Student",
    },
  ];
  return (
    <section className="section" id="testimonials">
      <Container>
        <Swiper
          slidesPerView={window.innerWidth < 768 ? 1 : 2}
          spaceBetween={20}
          pagination
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          loop
          loopFillGroupWithBlank
          fadeEffect={{ crossFade: true }}
          navigation
        >
          {slideData?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Card
                  className="overflow-scroll rounded-5"
                  style={{ height: "auto", maxHeight: "50vh" }}
                >
                  <CardBody>
                    <div className="text-center px-lg-4">
                      <h3 className="text-primary mb-2 text-capitalize">
                        {item.title}
                      </h3>
                      <hr />
                      {item.content}
                      <hr />
                      <h6>{item.userName}</h6>
                      <Rating
                        initialRating={5}
                        fullSymbol="mdi mdi-star text-warning font-size-24"
                        readonly
                      />
                      <h6 className="py-2">{item.occupation}</h6>
                    </div>
                  </CardBody>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </section>
  );
};
