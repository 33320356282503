import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Components
import FooterLink from "./footer-link";

const Features = () => {
  const footerLinks = [
    {
      title: "Quick Links",
      links: [
        { title: "About Us", link: "about" },
        { title: "Contact Us", link: "contact-us" },
        { title: "Solutions (shops)", link: "shop" },
        { title: "Testiomonial", link: "testimonials" },
        { title: "Terms and conditions", link: "terms-and-conditions" },
        { title: "Privacy Policy", link: "privacy-policy" },
        { title: "Affiliate Program", link: "affliate-program" },
      ],
    },
    {
      title: "Our Services",
      links: [
        { title: "Foreign Settlement", link: "foreign-settlement" },
        { title: "Signature Analysis", link: "signature-analysis" },
        { title: "Home Vastu", link: "home-vastu" },
        {
          title: "Relationship Problem Solution",
          link: "love-relationship-solutions",
        },
        {
          title: "Engagement and Marriage date recommendation ",
          link: "engagement-and-marriage-date-recommendation",
        },
        {
          title: (
            <span>
              Career Counseling
              <br />
              Business and job recommendation
            </span>
          ),
          link: "career-counseling-job-and-business-recommendation",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <footer className="landing-footer bg-gradient px-4">
        <Container>
          <Row>
            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <Link to={Flink.link}>{Flink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
            <Col lg="3" sm="6">
              <div className="mb-4 mb-lg-0">
                <h5 className="mb-3 footer-list-title">Contact Us</h5>
                <ul className="list-unstyled footer-list-menu">
                  <li>
                    <a
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      solution@numerolution.com
                    </a>
                  </li>
                  <li>
                    <a target="_blank" type="tel" href="tel:+917043329693">
                      +917043329693
                    </a>
                  </li>
                  <li>
                    908, Ganesh Glory, Gota Ahmedabad, Gujarat, India 382481
                  </li>
                </ul>
                <div>
                  <a
                    href="https://twitter.com/NumerolutionO1"
                    target="_blank"
                    className="social-icon"
                  >
                    <img
                      className="px-2"
                      height="30"
                      src="https://upload.wikimedia.org/wikipedia/commons/4/4f/Twitter-logo.svg"
                    />
                  </a>
                  <a href="#" target="_blank" className="social-icon">
                    <img
                      className="px-2"
                      height="30"
                      src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                    />
                  </a>
                  <a
                    href="https://instagram.com/numerolution"
                    target="_blank"
                    className="social-icon"
                  >
                    <img
                      className="px-2"
                      height="30"
                      src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg"
                    />
                  </a>
                </div>
              </div>
            </Col>

            <Col lg="3" sm="6">
              <div className="mb-4 mb-lg-0">
                <h5 className="mb-3 footer-list-title">
                  We Accept Payments from
                </h5>
                <div className="blog-post">
                  <img
                    className="py-1"
                    height="100"
                    src="https://www.logo.wine/a/logo/PhonePe/PhonePe-Logo.wine.svg"
                    alt="upi"
                  />
                  <img
                    className="py-1"
                    height="40"
                    src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                    alt="upi"
                  />
                  <img
                    className="py-1"
                    height="40"
                    src="https://upload.wikimedia.org/wikipedia/commons/e/e1/UPI-Logo-vector.svg"
                    alt="upi"
                  />
                  <img
                    className="py-1"
                    height="40"
                    src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg"
                    alt="upi"
                  />
                  <span className="py-1">
                    <img
                      height="80"
                      className="rounded"
                      src="https://www.numerolution.com/wp-content/uploads/2020/12/american-express.png"
                      alt="upi"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="footer-border my-5" />
          <FooterLink />
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Features;
