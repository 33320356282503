import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import OnePager from "pages/Home/CryptoIcoLanding/index";

//Products
import AddEditProducts from "pages/Products/AddProducts";
import ProductList from "pages/Products/ProductList";
import ProductDetails from "pages/Products/ProductDetails";

const authProtectedRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  //profile
  { path: "/", exact: true, component: () => <Redirect to="/product/list" /> },

  //Products
  { path: "/product/add", exact: true, component: AddEditProducts },
  { path: "/product/list", exact: true, component: ProductList },
  { path: "/product/detail/:id", exact: true, component: ProductDetails },

  //pdf
];

const publicRoutes = [
  { path: "/privacy-policy", exact: true, component: OnePager },
  { path: "/product/add", exact: true, component: AddEditProducts },
  { path: "/product/list", exact: true, component: ProductList },
  { path: "/product/detail/:id", exact: true, component: ProductDetails },
  { path: "*", exact: true, component: OnePager },
  { path: "/home-vastu", exact: true, component: OnePager },
  { path: "/signature-analysis", exact: true, component: OnePager },
  { path: "/foreign-settlement", exact: true, component: OnePager },
  { path: "/love-relationship-solutions", exact: true, component: OnePager },
  {
    path: "/engagement-and-marriage-date-recommendation",
    exact: true,
    component: OnePager,
  },
  {
    path: "/career-counseling-job-and-business-recommendation",
    exact: true,
    component: OnePager,
  },
  { path: "/shop", exact: true, component: OnePager },
  { path: "/shop/cart", exact: true, component: OnePager },
  { path: "/shop/product-details/:id", exact: true, component: OnePager },
  { path: "/shop/products", exact: true, component: OnePager },
  { path: "/shop/checkout", exact: true, component: OnePager },
  { path: "/shop/order-status", exact: true, component: OnePager },
  { path: "/pdf", exact: true, component: OnePager },
  { path: "/get-pdf/:order_id/:order_token", exact: true, component: OnePager },
  { path: "/contact-us", exact: true, component: OnePager },
];

export { authProtectedRoutes, publicRoutes };
