import React from "react";

export const WhyUs = () => {
  return (
    <React.Fragment>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <h1 className="text-center text-white pb-2">Why Numerolution</h1>
        <div className="full-flex pt-4 row p-2">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-2">
                <i
                  style={{ fontSize: "2.5rem" }}
                  className="mdi mdi-file-document zoom-animation"
                />
              </div>
              <div className="col-10">
                <h4 className="text-white">Qualitative Life Report</h4>
                <p>
                  We are using ancient numerology techniques to deliver you the
                  perfect result based on your birthdate.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-2">
                <i
                  style={{ fontSize: "2.5rem" }}
                  className="mdi mdi-reminder zoom-animation"
                />
              </div>
              <div className="col-10">
                <h4 className="text-white">Evergreen REMEDIES</h4>
                <p>
                  We are providing the NON EXPENSIVE evergreen remedies
                  according to your birthdate.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-2">
                <i
                  style={{ fontSize: "2.5rem" }}
                  className="mdi mdi-phone-in-talk zoom-animation"
                />
              </div>
              <div className="col-10">
                <h4 className="text-white">24 x 7 Support</h4>
                <p>
                  We are always there for you round the clock. When you have any
                  query then, never hesitate to contact us via given contact
                  details.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-2">
                <i
                  style={{ fontSize: "2.5rem" }}
                  className="mdi mdi-security zoom-animation"
                />
              </div>
              <div className="col-10">
                <h4 className="text-white">Confidential Data</h4>
                <p>
                  we understand and admire your privacy concern , so never worry
                  about your data because, it is highly secured with us.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-2">
                <i
                  style={{ fontSize: "2.5rem" }}
                  className="mdi mdi-account-multiple zoom-animation"
                />
              </div>
              <div className="col-10">
                <h4 className="text-white">Dedicated Team</h4>
                <p>
                  We have dedicated team working exceptionally to take care of
                  your online time slot booking with our specialist.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-2">
                <i
                  style={{ fontSize: "2.5rem" }}
                  className="mdi mdi-clock-time-eight zoom-animation"
                />
              </div>
              <div className="col-10">
                <h4 className="text-white">Deadlines</h4>
                <p>
                  We provide numerology report about your life instantly,so no
                  waiting time after purchasing the same report to know about
                  your Birth Date mystery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 text-center bg-white">
        <h2>43,225+</h2>
        <h4>Reports Downloaded</h4>
      </div>
      <div
        className="font-size-16"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <h1 className="text-white pb-3">
              What is Numerolution.com Is All About ?
            </h1>
            <p>
              numerolution.com is a web portal to know about your birthdate
              Mistry through Numerology. <br />
              <br /> Our team of numerology specialist will assist you to solve
              your negative numbers effects from your life so that one can live
              better life as we are hear in this world to enjoy our life and not
              get in to trouble all the time , as numerology has its own effect
              on human life so we decided to cure human problems through website
              [numerolution.com] with backing of ethical, proven methodology.{" "}
              <br />
              <br />
              We as a team with intention to cure your problems as best we can
              through this web portal that is what who we are.
            </p>
          </div>
          <div className="col-1" />
          <div className="col-lg-5 col-md-11 col-sm-11">
            <img
              height="300"
              src="https://www.numerolution.com/wp-content/uploads/2020/07/Design2.png"
            />
          </div>
        </div>
      </div>
      <div
        className="font-size-16 bg-white"
        style={{
          padding: "10%",
          paddingTop: "2%",
          paddingBottom: "5%",
          color: "#c4c4c4",
        }}
      >
        <h1 className="py-3 text-primary">How Numerolution.com works?</h1>
        <div className="py-2 text-secondary">
          <p>
            Numerolution.com works on the Principles of Numerology where each
            and every number tells us about its worthiness and that number’s
            energy synchronizes with your life leaving great impacts on the
            same. We have started this on the basis of real life experience
            firstly experienced by Numerolution.com’s team by its self so we
            decided to spread the worthiness of numerology in Human life.
          </p>
        </div>
        <div
          style={{ backgroundColor: "#f7fff7" }}
          className="border border-success border-opacity-25 p-2 rounded text-secondary font-monospace"
        >
          <span>
            Note : When you feel our results worth your attention than click
            here to know more interesting facts about your life.
          </span>
        </div>
      </div>
      <div
        className="font-size-16"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div className="py-4">
          <h1 className="text-white text-center">
            The report contains following factors of your life.
          </h1>
          <div className="row py-3">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <ul style={{ listStyle: "none" }}>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Your career-success and occupation
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  About husband/wife – relations-partnership
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Your Cleverness family relations -health
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Property , money and luck
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Health , stability , focus
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <ul style={{ listStyle: "none" }}>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Friends , travel and new beginning
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Creativity , kids planning
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Your Knowledge-self motivation-Intelligence
                </li>
                <li className="p-2">
                  <i className="mdi text-bg-success rounded px-1 text-white mdi-check-circle mx-2" />
                  Your fame – prosperity – light
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
