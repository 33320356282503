import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { setItem, getItem } from "service/utils";
import { getSecureToken, setSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";
import { ApiServices } from "service/apiServices";
import * as url from "service/url_helper";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { FormControl } from "react-bootstrap";

const Section = (props) => {
  const countryCode = sessionStorage.getItem("countryCode") ?? "IN";

  const languages = ["Gujarati", "English", "Hindi"];
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      contact: "",
      dob: "",
      language: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      contact: Yup.string().required("Please Enter Your Phone Number"),
      dob: Yup.string().required("Please Select Your Birthdate"),
      language: Yup.string().required("Language is required"),
      email: Yup.string()
        .required("Please enter your email address")
        .email("Not a valid email address"),
    }),
    onSubmit: (values) => {
      let uri = url.CREATE_PDF_USER;
      ApiServices.callServicePostWithBodyData(uri, values).then((response) => {
        if (response.result.type === "success") {
          props.history.push({
            pathname: "/pdf",
            state: {
              user: response?.result?.data,
              language: validation.values.language,
            },
          });
        }
      });
    },
  });
  // useEffect(() => {
  //   console.log("Error", validation.errors);
  //   console.log("Value", validation.values);
  // }, [validation]);
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <Container>
          <Row className="align-items-center px-4">
            <Col lg="5">
              <div className="text-white-50">
                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                  Welcome To The Eye Opening Experience Of Life
                </h1>
                <p className="font-size-14">
                  Fill The Details To Unveil The Mystery Of Your Life
                </p>
              </div>
            </Col>
            <Col lg="5" md="8" sm="10" className="ms-lg-auto">
              <Card
                className="mb-0 mt-5 mt-lg-0"
                style={{
                  boxShadow: "0px 0px 10px 2px #62ffca",
                  opacity: "0.95",
                  borderRadius: "10px",
                }}
              >
                <CardBody>
                  <div className="mt-4">
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit(e);
                        return false;
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Mobile number <sup className="text-danger">*</sup>{" "}
                            </Label>
                            <PhoneInput
                              inputComponent={FormControl}
                              international
                              countryCallingCodeEditable={false}
                              placeholder="Enter phone number"
                              value={validation.values.contact}
                              defaultCountry={countryCode}
                              onChange={(e) => {
                                validation.setFieldValue("contact", e);
                              }}
                              limitMaxLength
                              onBlur={(e) =>
                                validation.handleBlur("contact", e)
                              }
                              focusInputOnCountrySelection
                            />
                            {/* <Input
                              name="contact"
                              placeholder="Mobile number"
                              type="number"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.contact || ""}
                              invalid={
                                validation.touched.contact &&
                                validation.errors.contact
                                  ? true
                                  : false
                              }
                            /> */}
                            {validation.touched.contact &&
                            validation.errors.contact ? (
                              <small className="text-danger">
                                {validation.errors.contact}
                              </small>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Birth Date <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="dob"
                              placeholder="Your birthdate"
                              type="date"
                              className="form-control"
                              id="validationCustom03"
                              min={Date.now()}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.dob || ""}
                              invalid={
                                validation.touched.dob && validation.errors.dob
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.language &&
                            validation.errors.dob ? (
                              <FormFeedback type="invalid">
                                {validation.errors.dob}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Language <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="language"
                              placeholder="Choose language"
                              type="select"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.language || ""}
                              invalid={
                                validation.touched.language &&
                                validation.errors.language
                                  ? true
                                  : false
                              }
                            >
                              <option value="" disabled hidden>
                                Choose Language
                              </option>
                              {languages?.map((item, key) => (
                                <option key={key}>{item}</option>
                              ))}
                            </Input>
                            {validation.touched.language &&
                            validation.errors.language ? (
                              <FormFeedback type="invalid">
                                {validation.errors.language}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Email <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="email"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-center mt-4">
                        <Button color="success" type="submit">
                          Get Results Now
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
