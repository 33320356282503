import React, { useEffect, useState } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Badge,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

import classnames from "classnames";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { Constants } from "helpers/constant.js";
import { options } from "../../helpers/ReactSelectOptions";
import Dropzone from "react-dropzone";
import { getCompressedImages } from "helpers/utils.js";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import constant from "../../service/constant";

//import sweet alert
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const PropertyDetails = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  document.title = "Product Details | Numerolution";
  const [productDetails, setProductDetails] = useState({});
  const [propertyDetails2, setPropertyDetails2] = useState({});
  const [deleteModal, setDeleteModal] = useState({ value: false });
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [imgType, setImgType] = useState();
  const [propertyFront, setPropertyFront] = useState([]);
  const [propertyBack, setPropertyBack] = useState([]);
  const [corridorImgs, setCorridorImgs] = useState([]);
  const [roomImgs, setRoomImgs] = useState([]);
  const [moreImgs, setMoreImgs] = useState([]);
  const [propertyFrontLimit, setPropertyFrontLimt] = useState();
  const [propertyBackLimit, setPropertyBackLimit] = useState();
  const [corridorImgsLimit, setCorridorImgsLimit] = useState();
  const [roomImgsLimit, setRoomImgsLimit] = useState();
  const [moreImgsLimit, setMoreImgsLimit] = useState();
  const [disable, setDisable] = useState(false);

  const getProductById = () => {
    let uri = url.GET_PRODUCT_BY_ID + props.match.params.id;
    ApiServices.callServiceGet(uri)
      .then((response) => {
        if (response?.result) {
          setProductDetails(response.result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  useEffect(() => {
    getProductById();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Nav tabs className="nav-tabs-custom nav-justified prop-tab-width ">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="d-sm-block">Details</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span className=" d-sm-block">Images</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab} className=" my-3">
            <TabPane tabId="1">
              <Row>
                <Col sm={12} className="tab-user-card">
                  <Row className="mb-n4">
                    <Col>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-start">
                              <h3 className="mb-0 text-primary fw-bold">
                                {productDetails.productName}
                              </h3>
                              <Badge
                                className={
                                  "badge-soft-success rounded-pill ms-3 px-3 py-1"
                                }
                                style={{ fontSize: "0.7rem" }}
                              >
                                {"Rs. " + productDetails?.price}
                              </Badge>
                            </Card.Body>

                            {/* <Card.Body className="row">
                              <Col className="col-lg-6 col-md-6 col-sm-12">
                                <Row className="form-group">
                                  <label
                                    style={{ paddingTop: "inherit" }}
                                    className="col-sm-6 col-form-label font-weight-bolder"
                                  >
                                    Benifits Of Product
                                  </label>
                                  <Col sm={12}>
                                    <span style={{color:"#525252"}} dangerouslySetInnerHTML = {{ __html: productDetails.benifitsOfProduct }} />
                                  </Col>
                                </Row>
                              </Col>
                            </Card.Body> */}
                          </Card>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Product Specification</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Weight
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.weight ?? "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Base Material
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification
                                    ?.baseMaterial || "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Base Color
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.color || "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Base Dimensions
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.dimensions ||
                                    "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Base Model Name
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.modelName ||
                                    "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Size
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.size || "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-6 col-form-label font-weight-bolder"
                                >
                                  Product Type
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.type || "-"}
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <h5>Product Photos</h5>
                      <Card>
                        <Card.Body>
                          <div className="row">
                            <div className="col">
                              {productDetails.productImages &&
                              productDetails.productImages.length > 0 ? (
                                <>
                                  <Row className="p-3">
                                    <h5>Product Images</h5>
                                  </Row>
                                  <Row>
                                    {productDetails.productImages.map(
                                      (item, key) => (
                                        <div
                                          key={key}
                                          className="col-lg-4 col-md-auto col-sm-auto"
                                        >
                                          <Card className="m-0">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "250px",
                                                borderRadius: 10,
                                              }}
                                              className="responsive img-fluid wid-100"
                                              src={
                                                constant.IMG_URL +
                                                item.imagePath
                                              }
                                              alt="User"
                                            />
                                          </Card>
                                        </div>
                                      )
                                    )}
                                  </Row>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PropertyDetails;
