import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";

//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import constant from "service/constant";
import BackgroundImageSecondary from "assets/images/crypto/bg-ico-hero.jpg";

const ProductDetails = (props) => {
  //meta title
  document.title = "Product Details | Numerolution";

  const [product, setProductDetails] = useState({});

  const {
    match: { params },
    history,
  } = props;
  const currencyString = sessionStorage.getItem("currencyString");

  const productId = params[0]?.split("/")?.slice(-1)?.toString();
  const [activeTab, setActiveTab] = useState("0");
  const [quantity, setQuantity] = useState(1);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = constant.IMG_URL + img;
  };
  const manageQuantity = (type) => {
    if (quantity === 1 && type === "minus") return;
    if (type === "minus") return setQuantity((pre) => (pre -= 1));
    if (type === "plus") return setQuantity((pre) => (pre += 1));
  };
  const getProductDetail = () => {
    if (!currencyString) return;
    let uri = url.GET_PRODUCT_BY_ID + productId;
    ApiServices.callServiceGet(uri)
      .then((response) => {
        if (response?.result) {
          setProductDetails(response.result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };
  function description(data) {
    return { __html: `${product[data]}` };
  }

  const onClickBuy = (product) => {
    let orderObj = [
      {
        productId: productId,
        quantity: quantity,
        price: !isNaN(parseInt(product?.price)) ? parseInt(product?.price) : 0,
        productTotal:
          !isNaN(parseInt(product?.price)) && !isNaN(parseInt(quantity))
            ? parseInt(product.price) * parseInt(quantity)
            : 0,
        productImg: product?.productImages[0],
        productName: product?.productName,
      },
    ];
    history.push({ pathname: "/shop/checkout", state: { orderObj: orderObj } });
  };

  useEffect(() => {
    getProductDetail();
  }, [currencyString]);

  return (
    <React.Fragment>
      <section
        className="section hero-section"
        style={{
          backgroundColor: "#131521",
          // backgroundImage: `url(${BackgroundImageSecondary})`,
          backgroundPosition: "top",
          backgroundRepeat: "repeat",
        }}
      >
        <Container fluid>
          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md="2" xs="3">
                              <Nav className="flex-column" pills>
                                {product.productImages &&
                                  product.productImages.map((image, index) => (
                                    <NavItem key={index}>
                                      <NavLink
                                        className={classnames({
                                          active: activeTab === String(index),
                                        })}
                                        onClick={() => {
                                          toggleTab(String(index));
                                        }}
                                      >
                                        <img
                                          src={
                                            constant.IMG_URL + image.imagePath
                                          }
                                          alt=""
                                          onClick={() => {
                                            imageShow(image.imagePath, index);
                                          }}
                                          className="img-fluid mx-auto d-block rounded"
                                        />
                                      </NavLink>
                                    </NavItem>
                                  ))}
                              </Nav>
                            </Col>
                            <Col md={{ size: 7, offset: 1 }} xs="9">
                              <TabContent activeTab={activeTab}>
                                {product?.productImages &&
                                  product?.productImages.map((image, index) => (
                                    <TabPane tabId={String(index)} key={index}>
                                      <div key={index}>
                                        <img
                                          src={
                                            constant.IMG_URL + image.imagePath
                                          }
                                          alt=""
                                          id={"expandedImg" + String(index)}
                                          className="img-fluid mx-auto d-block"
                                        />
                                      </div>
                                    </TabPane>
                                  ))}
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          {/* <Link to="#" className="text-primary">
                            {product.category}
                          </Link> */}
                          <h4 className="mt-1 mb-3">{product?.productName}</h4>
                          <Row>
                            <div className="text-muted float-start me-3 mb-2">
                              <StarRatings
                                rating={5}
                                starRatedColor="#F1B44C"
                                starEmptyColor="#2D363F"
                                numberOfStars={5}
                                name="rating"
                                starDimension="14px"
                                starSpacing="3px"
                              />
                            </div>

                            {!isEmpty(product?.reviews) && (
                              <p className="text-muted mb-4">
                                ( {product?.reviews?.length ?? "100"} Customers
                                Review )
                              </p>
                            )}
                          </Row>

                          {!!product.isOffer && (
                            <h6 className="text-success text-uppercase">
                              {product.offer} % Off
                            </h6>
                          )}
                          <h5 className="mb-4">
                            Price :{" "}
                            <span className="text-muted me-2">
                              {/* <del>₹{String(parseInt(product.price)+1000)}</del> */}
                            </span>{" "}
                            <b>
                              {product.price} {currencyString}
                            </b>
                          </h5>
                          <div style={{ width: "120px" }}>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    manageQuantity("plus");
                                  }}
                                >
                                  +
                                </button>
                              </div>
                              <Input
                                className="text-center"
                                type="text"
                                value={quantity}
                                name="demo_vertical"
                                readOnly
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    manageQuantity("minus");
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            </div>
                          </div>
                          <br />
                          <Row>
                            <div className="text-left">
                              <Col>
                                {props.isItemAddedToCart(product) ? (
                                  <Button
                                    type="button"
                                    color="secondary"
                                    className="btn  mt-2 me-1 d-inline-flex align-items-center gap-2"
                                    onClick={() => {
                                      props.removeFromCart(product);
                                    }}
                                  >
                                    <i className="bx bx-minus-circle bx-xs" />{" "}
                                    Remove From Cart
                                  </Button>
                                ) : (
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn  mt-2 me-1 w-lg d-inline-flex justify-content-center  align-items-center gap-2"
                                    onClick={() => {
                                      let obj = {
                                        _id: productId,
                                        productId: productId,
                                        quantity: quantity,
                                        price: !isNaN(parseInt(product?.price))
                                          ? parseInt(product?.price)
                                          : 0,
                                        productTotal:
                                          !isNaN(parseInt(product?.price)) &&
                                          !isNaN(parseInt(quantity))
                                            ? parseInt(product.price) *
                                              parseInt(quantity)
                                            : 0,
                                        productImg: product?.productImages[0],
                                        productName: product?.productName,
                                      };
                                      props.addToCart(obj);
                                    }}
                                  >
                                    <i className="bx bx-plus-circle bx-xs" />{" "}
                                    Add to cart
                                  </Button>
                                )}
                                <Button
                                  type="button"
                                  color="success"
                                  className="ms-1 btn w-lg mt-2 d-inline-flex justify-content-center align-items center gap-2"
                                  onClick={() => onClickBuy(product)}
                                >
                                  <i className="bx bx-shopping-bag bx-xs" />
                                  Buy now
                                </Button>
                              </Col>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              {product.features &&
                                product.features.map((item, i) => (
                                  <div key={i}>
                                    <p className="text-muted">
                                      <i
                                        className={classnames(
                                          item.icon,
                                          "font-size-16 align-middle text-primary me-2"
                                        )}
                                      />
                                      {item.type && `${item.type}: `}
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                            </Col>
                            <Col md="6">
                              {product.features &&
                                product.features.map((item, i) => (
                                  <div key={i}>
                                    <p className="text-muted">
                                      <i
                                        className={classnames(
                                          item.icon,
                                          "font-size-16 align-middle text-primary me-2"
                                        )}
                                      />
                                      {item.type && `${item.type}:`}
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                            </Col>
                          </Row>

                          {/* <div className="product-color">
                            <h5 className="font-size-15">Color :</h5>
                            {product.colorOptions &&
                              product.colorOptions.map((option, i) => (
                                <Link to="#" className="active" key={i}>
                                  <div className="product-color-item border rounded">
                                    <img
                                      src={productImages[option.image]}
                                      alt=""
                                      className="avatar-md"
                                    />
                                  </div>
                                  <p>{option.color}</p>
                                </Link>
                              ))}
                          </div> */}
                        </div>
                      </Col>
                    </Row>

                    <br />
                    <br />
                    {!isEmpty(product.productDescription) && (
                      <>
                        <h5 className="mb-3">Description :</h5>
                        <div
                          className="text-muted mb-4"
                          dangerouslySetInnerHTML={description(
                            "productDescription"
                          )}
                        ></div>
                      </>
                    )}
                    {!isEmpty(product.benifitsOfProduct) && (
                      <>
                        <h5 className="mb-3">Benifits :</h5>
                        <div
                          className="text-muted mb-4"
                          dangerouslySetInnerHTML={description(
                            "benifitsOfProduct"
                          )}
                        ></div>
                      </>
                    )}
                    {!isEmpty(product.howToWear) && (
                      <>
                        <h5 className="mb-3">How to Wear :</h5>
                        <div
                          className="text-muted mb-4"
                          dangerouslySetInnerHTML={description("howToWear")}
                        ></div>
                      </>
                    )}
                    {!isEmpty(product.legelDesclaimer) && (
                      <>
                        <h5 className="mb-3">Legal Disclaimer :</h5>
                        <div
                          className="text-muted mb-4"
                          dangerouslySetInnerHTML={description(
                            "legelDesclaimer"
                          )}
                        ></div>
                      </>
                    )}
                    {!isEmpty(product.safetyInfo) && (
                      <>
                        <h5 className="mb-3">Safety Information :</h5>
                        <div
                          className="text-muted mb-4"
                          dangerouslySetInnerHTML={description("safetyInfo")}
                        ></div>
                      </>
                    )}
                    {/* <div className="mt-5">
                      <h5 className="mb-3">Specifications :</h5>

                      <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            {product.specification &&
                              Object.keys(product.specification).map((type, i) => (
                                <tr key={i}>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    {type}
                                  </th>
                                  <td>{product.specification[type]}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div> */}

                    {/* <Reviews
                      comments={[...comments]}
                      productId={params.id || 1}
                      onClickReply={onClickReply}
                      onCancelReply={onCancelReply}
                      onAddReply={onAddReply}
                      onAddComment={onAddComment}
                    /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {/* <RecentProducts recentProducts={product.recentProducts} /> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ProductDetails;
