import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, TabPane } from "reactstrap";

//Import Components
import Accordian from "./accordian";

const FAQs = () => {
  const questionAnswers = [
    {
      question: "Why you trust NUMEROLUTION?",
      answer:
        "You trust us because we are the successful happy team who once was facing the same issues which our clients or potential clients would have faced.We developed the methodology and solved the mystery of numerology through rich experience of life… We are here to help you because we have decided to dedicate our career in solving the problems of human lives through numerology.We provide free numero reports in which few points have been covered so with the backing of knowing those few points you can know weather those are true or what .If you have any kinds of query we are always available for online support and you can find our contact details in contact us section.",
    },
    {
      question: "What is there in entire reports?",
      answer: (
        <ul>
          <li>
            Entire reports on your birthdate containing positive and negative
            aspects.
          </li>
          <li>
            Solutions to clear the negative energies of the number you luck
            with.
          </li>
          <li>Worth to buy the facts that contains the truth.</li>
        </ul>
      ),
    },
    {
      question: "Why numerolution charge few money for reports?",
      answer:
        "Because numerolution team wants reach millions more whose life has been waiting to be treated through unique and proven method and that is numerology.",
    },
    {
      question: "Is it secured?",
      answer:
        "It is completely secured pay online on our website because we have secured payment trust certificates.",
    },
    {
      question: "What if I need discount?",
      answer:
        "You and your family members will get multi user pass in order to get the discvount , you can see on my account section .",
    },
    {
      question: "What happens after successful payment ?",
      answer:
        "There will be a pdf waiting to be downloaded containing your birthdate secrets to be revealed front of your eyes.",
    },
    {
      question: "In case if any querry rise in my mind in future then ?",
      answer:
        "We are there to provide round the clock support via call , e mail , zoom or skype .",
    },
    {
      question: "What is the important about selecting",
      answer: (
        <ul>
          <li>
            You are on this earth to live life fully and the day you find your
            soul mate is the most unique and beautiful day and the date itself
            has a huge impact on your life as a couple , you can contact us to
            know more about the same…
          </li>
          <li>
            Your marriage date will decide that how beautiful your life will be
            like together as one.
          </li>
          <li>
            Click HERE to know more about your marriage date or request a one.
          </li>
          <li>
            <u>
              NOTE : YEAR AND MONTH WOULD BE CONSIDERED BY CLIENT SIDE AND WE
              WOULD PROVIDE MULTIPLE CHOICES OF DATE AS PER COUPLE’S BIRTHDATE .
            </u>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQs</div>
                <h3>Frequently asked questions</h3>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="12" sm="12">
                    <Card>
                      <CardBody>
                        <TabPane tabId="1" id="buy">
                          <h4 className="card-title mb-4">General Questions</h4>

                          {/* accoridan */}
                          <Accordian questionAnswers={questionAnswers} />
                        </TabPane>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FAQs;
