//LOGIN
export const LOGIN_PASSWORD = "/auth-partner/login-password";
export const SEND_FEEDBACK = "/auth/contact-us";

//currency
export const USER_CURRENCY = "/auth/user-country-currency";

// shop
export const PRODUCT_LIST = "/product/category/";
export const CREATE_ORDER = "/order";
export const PREVIEW_ORDER = "/order/preview";

// product
export const ADD_PRODUCT = "/product";
export const GET_PRODUCT_BY_ID = "/product/";
export const GET_ALL_PRODUCTS = "/product";

// category
export const GET_ALL_CATEGORY = "/category";

//Payment
export const APPLY_CODE = "/admin/affiliate/check-code";
export const INITIATE_PAYMENT = "/payment/pay";
export const INITIATE_PAYMENT_FOR_PDF = "/payment/pay-pdf";
export const GET_PAYMENT_FOR_PDF = "/payment/get-pdf";
export const GET_PAYMENT_STATUS = "/payment/get";

//Pdf
export const CREATE_PDF_USER = "/pdf/create-user";
