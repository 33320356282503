import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  ModalFooter,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";
import BLIV_Partner from "assets/images/logo-small-nobg_new.png";
import BackgroundImage from "assets/images/num_result_bg.jpeg";
import BackgroundImageSecondary from "assets/images/crypto/bg-ico-hero.jpg";
import * as apiUrl from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { getSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";
import service from "../../service/constant.js";

const ReadyPdf = (props) => {
  //meta title
  document.title = "Result | Numerolution";
  const [product, setProject] = useState();

  const lang = props?.location?.state?.language?.toLowerCase() ?? "gujarati";
  const userData = props?.location?.state.user;
  const currencyCode = sessionStorage.getItem("currencyCode")

  const initiatePayment = () => {
    try {
      let uri = apiUrl.INITIATE_PAYMENT_FOR_PDF;
      let reqObj = {
        orderAmount: 100,
        pdfUserId: userData._id,
        return_url: `${service.PAYMENT_URL}/#/get-pdf/`,
      };
      ApiServices.callServicePostWithBodyData(uri, reqObj).then((response) => {
        window.location.replace(response.result.data.payment_link);
      });
    } catch (error) {
      console.log("error in initiate payment", error);
    }
  };

  return (
    <React.Fragment>
      <section
        className="section hero-section"
        style={{
          // backgroundColor: "#131521",
          backgroundImage: `url(${BackgroundImageSecondary})`,
          backgroundPosition: "top",
          backgroundRepeat: "repeat",
        }}
      >
        <Container>
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">Your Report is ready</h1>
            <br />
            <p className="font-size-18">
              You Can Download this report just after payment formalities...
              Behold....the eye shocking experience of your life is just a click
              away
            </p>
          </div>
          <div
            className="font-size-14 mx-auto py-3"
            style={{
              display: "flex",
              paddingLeft: "20%",
              paddingRight: "20%",
              justifyContent: "center",
              // backgroundColor: "#131521",
              color: "#c4c4c4",
              width: "100%",
              // backgroundImage: `url(${BackgroundImageSecondary})`,
              // backgroundRepeat: 'repeat',
              // backgroundPosition : 'center',
            }}
          >
            <button
              style={{
                position: "absolute",
                alignSelf: "center",
                alignItems: "center",
                fontSize: "1.8vw",
                padding: "2vw",
                fontWeight: "bold",
              }}
              className="glow-on-hover"
              onClick={() => initiatePayment()}
            >
              Download
              <br />
              {`${userData?.name?.split(" ")[0]}.pdf` ?? "Download here"}
            </button>
            <img
              src={require(`assets/images/${lang}_img.png`)}
              style={{ objectFit: "cover", width: "100%" }}
            ></img>
          </div>
          <div className="align-items-center mt-5  px-4 text-white text-left">
            <h3 className="text-light-green">Resolution</h3>
            <p>
              To Resolve negative point's energy from your Birth date we are
              providing solution that is also mentioned in your exclusive life
              report.Numerolution.com provides non expensive solution.
            </p>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ReadyPdf;
