import React from "react";
import { Container, Row, Col } from "reactstrap";

export const SignatureAnalysis = () => {
  document.title = "Numerolution | Signature Analysis";
  return (
    <React.Fragment>
      <section
        style={{
          background:
            "url(https://www.numerolution.com/wp-content/uploads/2020/07/5f02c21f4414e.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
        }}
        className="bg-ico-hero"
      >
        <Container
          className="section hero-section max-full"
          style={{
            backdropFilter: "blur(2px) brightness(0.3)",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">SIGNATURE ANALYSIS</h1>
            <br />
            <p className="font-size-18">
              Our signature or our identification determines the course of life
              in this birth, and the subsequent actions which can either lead to
              liberation or re-birth. There are many ways to sign a name, which
              bears many results, like improves popularity, success, fame,
              financial gains, win over enemies etc. Different styles of
              signatures yield different results. But I am going to discuss only
              the best and ideal way to sign your name, which is ideally suited
              .to bear our past karma’s in this life itself and to move closer
              to the path of liberation or salvation.
            </p>
            <br />
            <p className="font-size-20">
              AN APPOINTMENT TO KNOW MORE ABOUT OUR POPPULAR NUMEROLOGIST
              MR.PARTH PATEL RIGHT NOW.
            </p>
          </div>
        </Container>
      </section>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div className="row">
          <h1 className="text-light-green px-2 py-4 text-center">
            UNDERSTAND & IMPROVE
          </h1>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object@3x.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">NAME IDENTIFICATION</h3>
              <p>
                First of all, determine which name you want to sign. If your
                full name is the auspicious one, then you must use your full
                name as signature. Some people may have a lengthy name which
                includes first name + middle name + last name, though their full
                name is lucky as per numerology, they may not be in a position
                to sign the lengthy one; In that case, determine which part of
                the name is lucky for you, like First name or First Name + Last
                Name or First Name +Middle Name and sign accordingly. But
                remember, numerology is seen for full name and it’s always ideal
                to sign the full name. The name you sign is what counts the most
                in numerology and not the name in certificates or papers.
              </p>
            </div>
          </div>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/06/vector-smart-object-2@3x-1.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">IMPROVISING SIGNATURE</h3>
              <p>
                e.g, if your full name is John Turner, then sign as John Turner
                and not as John or Turner or J Turner. (If John Turner is
                Numerologically fortunate) If your full name is just first name
                and last, then it’s easy to sign the full name. But if your
                birth name is first+middle+last, and if you are using first name
                and last name in day to day activities, then check whether that
                name is fortunate or not, and then start signing the name you
                use for public purpose (first+last). In this way, your full name
                remains just as paper name in certificates, but the auspicious
                name is what you sign in your public life.
              </p>
            </div>
          </div>
        </div>
        {/* team */}
        <div className="py-5">
          <Container className="px-4">
            <h1 className="text-light-green text-center mb-4">
              UNDERSTANDING DIFFERENT STYLES OF SIGNATURES
            </h1>
            <Row className="align-items-center py-lg-5">
              <Col md="6" sm="8">
                <div>
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2022/08/trump_signature_analysis_1050x700-1024x683.png"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                    style={{ height: "30vh" }}
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto text-mid">
                <h2 className="text-info mb-3 mt-3">
                  Get Correction of your signature
                </h2>
                <p>
                  So guys never be in confused mode when the team
                  numerolution.com is all set to support you in your life
                  changing decision so...
                  <br />
                  <br />
                  BOOK AN APPOINTMENT TO KNOW MORE ABOUT YOUR SIGNATURE WITH OUR
                  POPULAR NUMEROLOGY SPECIALIST MR.PARTH PATEL RIGHT NOW BY
                  CLICKING ON APPOINTMENT SECTION.
                </p>
              </Col>
            </Row>
            <h1 className="text-light-green text-center mb-4 mt-4">
              BENEFITS OF SIGNATURE ANALYSIS THROUGH NUMEROLOGY
            </h1>
            <div className="px-lg-5 py-lg-4">
              <ul style={{ listStyle: "none" }} className="px-2">
                <li className="p-2">
                  <i className="mdi mdi-circle text-bg-success rounded px-1 text-white mx-2" />
                  Get your signature correction through numerology.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-circle text-bg-success rounded px-1 text-white mx-2" />
                  Signature correction can bring huge un interrupted success in
                  your life.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-circle text-bg-success rounded px-1 text-white mx-2" />
                  Signature must be as per numerology law as it can make or
                  ruined the big deals, career and life.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-circle text-bg-success rounded px-1 text-white mx-2" />
                  Get experts opinion about your life’s journey through
                  numerology and correct the things where it is necessary.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-circle text-bg-success rounded px-1 text-white mx-2" />
                  Online appointment facility so nowhere to go to get your
                  signature correction.
                </li>
              </ul>
            </div>
            <div className="m-lg-4 p-lg-4">
              <div className="border font-lg-size-16 font-monospace border-2 border-success p-lg-3 p-2 rounded text-secondary bg-success text-white">
                <span>
                  “The name you sign is what counts the most in numerology and
                  not the name in certificates or papers.”
                </span>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
