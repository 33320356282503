import React, { useEffect, useState } from "react";

//Import Components
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/footer";
import { LandingPage } from "../LandingPage";
import { AboutUs } from "./InnerComps/AboutUs";
import { HomeVastu } from "./InnerComps/HomeVastu";
import PagesComingsoon from "pages/Utility/pages-comingsoon";
import { SignatureAnalysis } from "./InnerComps/SignatureAnalysis";
import { ForeignSettlement } from "./InnerComps/ForeignSettlement";
import { LoveRelationship } from "./InnerComps/LoveRelationship";
import { Engagement } from "./InnerComps/Engagement";
import { CareerCounseling } from "./InnerComps/CareerCounseling";
import { ProductList } from "./Shop/ProductList";
import { CategoryList } from "./Shop/CategoryList";
import ReadyPdf from "pages/Pdf/ReadyPdf";
import GetPdf from "pages/Pdf/GetPdf";
import ProductDetails from "./Shop/ProductDetails";
import ShopCheckout from "./Shop/Checkout";
import OrderStatus from "./Shop/OrderStatus";
import ContactUs from "./InnerComps/ContactUs";
import Cart from "./Shop/Cart";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";

const CryptoIcoLanding = (props) => {
  const [imglight, setimglight] = useState(true);
  const [navClass, setnavClass] = useState("");
  const [cart, setCart] = useState([]);
  const path = window.location.hash;

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    if (window.innerWidth > 600) {
      hideNavOnScroll();
    }
    /**
     * Initial Values for cart after page reload or navigation
     */
    let cartItems = localStorage.getItem("cart");
    if (cartItems) {
      setCart(JSON.parse(cartItems));
    }
  }, []);

  /**
   * Cart Utility Functions Start
   */
  const addToCart = (product) => {
    let newItems = [...cart, product];
    setCart(newItems);
    localStorage.setItem("cart", JSON.stringify(newItems));
  };
  const removeFromCart = (product) => {
    let updatedItems = cart.filter((item) => item._id !== product._id);
    setCart(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
  };
  const isItemAddedToCart = (product) => {
    let isItemAddedToCart = cart.some((item) => item._id === product._id);
    return isItemAddedToCart;
  };

  const updateCart = (cartItems) => {
    setCart(cartItems);
    localStorage.setItem("cart", JSON.stringify(cartItems));
  };

  /**
   * Cart Utility Functions ends
   */

  function scrollNavigation() {
    let scrollup = document.documentElement.scrollTop;
    if (scrollup > 80) {
      setimglight(false);
      setnavClass("nav-sticky");
    } else {
      setimglight(true);
      setnavClass("");
    }
  }

  const hideNavOnScroll = () => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").style.top = "0";
      } else {
        document.getElementById("navbar").style.top = "-40%";
      }
      prevScrollpos = currentScrollPos;
    };
  };

  return (
    <React.Fragment>
      {/* import navbar */}
      <Navbar cart={cart} navClass={navClass} imglight={imglight} {...props} />
      {path === "" || path === "#/" || path === "#/home" ? (
        <LandingPage {...props} />
      ) : path === "#/about" ? (
        <AboutUs />
      ) : path === "#/contact-us" ? (
        <ContactUs />
      ) : path === "#/home-vastu" ? (
        <HomeVastu />
      ) : path === "#/signature-analysis" ? (
        <SignatureAnalysis />
      ) : path === "#/foreign-settlement" ? (
        <ForeignSettlement />
      ) : path === "#/love-relationship-solutions" ? (
        <LoveRelationship />
      ) : path === "#/engagement-and-marriage-date-recommendation" ? (
        <Engagement />
      ) : path === "#/career-counseling-job-and-business-recommendation" ? (
        <CareerCounseling />
      ) : path === "#/shop" ? (
        <CategoryList {...props} />
      ) : path === "#/shop/products" ? (
        <ProductList
          {...props}
          cart={cart}
          updateCart={updateCart}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      ) : path.includes("#/shop/product-details/") ? (
        <ProductDetails
          {...props}
          cart={cart}
          updateCart={updateCart}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          isItemAddedToCart={isItemAddedToCart}
        />
      ) : path === "#/shop/cart" ? (
        <Cart
          {...props}
          cart={cart}
          updateCart={updateCart}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
          isItemAddedToCart={isItemAddedToCart}
        />
      ) : path === "#/shop/checkout" ? (
          <ShopCheckout
          {...props}
          cart={cart}
          updateCart={updateCart}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      ) : path.includes("#/shop/order-status") ? (
        <OrderStatus
          {...props}
          cart={cart}
          updateCart={updateCart}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      ) : path === "#/pdf" ? (
        <ReadyPdf {...props} />
      ) : path.includes("get-pdf") ? (
        <GetPdf {...props} />
      ) : path === "#/privacy-policy" ? (
        <PrivacyPolicy {...props} />
      ) : (
        <PagesComingsoon />
      )}
      <div
        onClick={() => window.scrollTo(0, 0)}
        className="position-fixed rounded-circle bg-info text-white"
        style={{
          right: window.innerWidth - (window.innerWidth - 30),
          bottom: window.innerHeight - (window.innerHeight - 30),
          cursor: "pointer",
          fontSize: "2.5rem",
          zIndex: 999,
          opacity: imglight ? "0" : "1",
          transform: imglight
            ? "translateY(100px) scale(0)"
            : "translateY(0) scale(1)",
          transition: "all 2s",
          visibility: imglight ? "hidden" : "visible",
        }}
      >
        <i className="mdi mdi-home" style={{ padding: "0px 10px" }} />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CryptoIcoLanding;
