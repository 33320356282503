import React from "react";
// import { Col, Container, Form, Row, FormGroup, Label, Input } from 'reactstrap';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiServices } from "service/apiServices";
import * as url from "service/url_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export const Contact = () => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      contact: "",
      email: "",
      feedBack: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      contact: Yup.string().required("Please Enter Your Phone Number"),
      email: Yup.string().required("Please Enter Your Email Id"),
      feedBack: Yup.string().required("Please Enter Your FeedBack"),
    }),
    onSubmit: (values, { resetForm }) => {
      let uri = url.SEND_FEEDBACK;
      ApiServices.callServicePostWithBodyData(uri, values).then((response) => {
        if (response.result.type === "success") {
          if (response?.result) {
            //toastr.success(response?.result?.message ? response.result.message : '', "Success")
            const MySwal = withReactContent(Swal);
            MySwal.fire("Feed submitted successfully").then((res) => {
              resetForm();
            });
          } else {
            toastr.error(
              response?.result?.message
                ? response.result.message
                : "Error Occured",
              "Failed"
            );
          }
          // props.history.push({ pathname: "/pdf", state: { user: response?.result?.data, language: validation.values.language } })
        }
      });
    },
  });
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <Container>
          <Row className="align-items-center px-4">
            <Col lg="4">
              <div className="text-white-50">
                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                  Send your FeedBack to Us...
                </h1>
                <p className="font-size-14">
                  Fill The Details To Unveil The Mystery Of Your Life
                </p>
              </div>
            </Col>
            <Col lg="8" md="8" sm="10" className="ms-lg-auto">
              <Card
                className="mb-0 mt-5 mt-lg-0"
                style={{
                  boxShadow: "0px 0px 10px 2px #62ffca",
                  opacity: "0.95",
                  borderRadius: "10px",
                }}
              >
                <CardBody>
                  <div className="mt-4">
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Mobile number <sup className="text-danger">*</sup>{" "}
                            </Label>
                            <Input
                              name="contact"
                              placeholder="Mobile number"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.contact || ""}
                              invalid={
                                validation.touched.contact &&
                                validation.errors.contact
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contact &&
                            validation.errors.contact ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Email <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="email"
                              placeholder="Your email"
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Feedback <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              id="feedBack"
                              type="textarea"
                              className="form-control"
                              rows="2"
                              name="feedBack"
                              placeholder="Enter Your Feedback"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.feedBack}
                              invalid={
                                validation.touched.feedBack &&
                                validation.errors.feedBack
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.feedBack &&
                            validation.errors.feedBack ? (
                              <FormFeedback type="invalid">
                                {validation.errors.feedBack}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-center mt-4">
                        <Button color="info" type="submit">
                          Send Feedback
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
