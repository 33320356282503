import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const aadharCardRegExp = /^\d{4}\d{4}\d{4}$/;
const panCardRegExp = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
const isAlpha = /[a-zA-Z ]+/;
const isAlphaNumeric = /[0-9a-zA-Z ]+/;
const isSpace = /^\S+$/;
const isUpiId = /^[\w.-]+@[\w.-]+$/;
const isValidUrl =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const isGSTINNumber =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
function noWhitespace() {
  return this.transform((value, originalValue) =>
    /\s/.test(originalValue) ? NaN : value
  );
}
Yup.addMethod(Yup.number, "noWhitespace", noWhitespace);

export const loginSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .min(10, "Number must have 10 digits")
    .max(10, "Number must have 10 digits")
    .required("Please enter a registered Number")
    .matches(isSpace, "Spaces are not allowed")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  password: Yup.string()
    .label("Password")
    .matches(isSpace, "Spaces are not allowed")
    .required(),
});
export const loginwithOTP = Yup.object().shape({
  mobileNumber: Yup.string()
    .min(10, "Number must have 10 digits")
    .max(10, "Number must have 10 digits")
    .required("Please enter a registered Number")
    .matches(isSpace, "Spaces are not allowed")
    .matches(phoneRegExp, "Please enter valid mobile number"),
});
export const partnerSignup = Yup.object().shape(
  {
    hiringFor: Yup.string().min(1, "Must have at least 1 characters"),
    companyName: Yup.string()
      .label("Company Name")
      .min(1, "Must have at least 1 characters")
      .when("hiringFor", {
        is: (fieldTwo) => fieldTwo == "myOwnCompany",
        then: Yup.string().required("Please enter company/employer name"),
      }),
    consultancyName: Yup.string()
      .label("Consultancy Name")
      .min(1, "Must have at least 1 characters")
      .when("hiringFor", {
        is: (fieldTwo) => fieldTwo == "myClients",
        then: Yup.string().required("Please enter consultancy name"),
      }),
    companyWebsite: Yup.string()
      .label("Company website")
      .min(1, "Must have at least 1 characters")
      .when("hiringFor", {
        is: (fieldTwo) => fieldTwo == "myOwnCompany",
        then: Yup.string().required("Please enter company website"),
      })
      .matches(isValidUrl, "Enter correct url!"),
    consultancyWebsite: Yup.string()
      .label("Consultancy website")
      .min(1, "Must have at least 1 characters")
      .when("hiringFor", {
        is: (fieldTwo) => fieldTwo == "myClients",
        then: Yup.string().required("Please enter consultancy website"),
      })
      .matches(isValidUrl, "Enter correct url!"),
    numbersOfEmployees: Yup.string()
      .required("Please select number of employees")
      .label("Number of employees")
      .min(1, "Must have at least 1 characters"),
    clients: Yup.string()
      .label("Number of employees")
      .min(1, "Must have at least 1 characters")
      .when("hiringFor", {
        is: (fieldTwo) => fieldTwo == "myClients",
        then: Yup.string().required("Please enter the name of your clients"),
      }),
  },
  ["hiringFor"]
);

export const PropertyRegistrationSchema = Yup.object().shape(
  {
    aadharNumber: Yup.string()
      .label("Aadhar Card Number")
      .min(12)
      .max(12)
      .when("panNumber", {
        is: (a) => !a || a.length === 0,
        then: Yup.string().required("Aadhar Card or Pan Card of is mandatory"),
      })
      .matches(isSpace, "Spaces are not allowed")
      .matches(aadharCardRegExp, "Enter Valid Aadhar Number"),
    panNumber: Yup.string()
      .label("Pan Card Number")
      .min(10)
      .max(10)
      .when("aadharNumber", {
        is: (a) => !a || a.length === 0,
        then: Yup.string().required("Aadhar Card or Pan Card of is mandatory"),
      })
      .matches(isSpace, "Spaces are not allowed")
      .matches(panCardRegExp, "Enter Valid Pan Number"),
    gstInNumber: Yup.string()
      .label("GSTIN Number")
      .min(15)
      .max(15)
      .matches(isSpace, "Spaces are not allowed")
      .matches(isGSTINNumber, "Enter valid GSTIN Number"),
  },
  ["aadharNumber", "panNumber"]
);
// export const PropertyRegistrationSchema =  Yup.object().shape({
//   aadharNumber: Yup.string()
//   .label('Aadhar Card Number')
//   .min(12)
//   .max(12)
//   .when(["gstInNumber", "panNumber"], {
//     is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
//     then: Yup.string().required("One of the documents is mandatory")
//   })
//   .matches(isSpace,'Spaces are not allowed')
//   .matches(aadharCardRegExp,'Enter Valid Aadhar Number'),
//   panNumber: Yup.string()
//   .label('Pan Card Number')
//   .min(10)
//   .max(10)
//   .when(["gstInNumber", "aadharNumber"], {
//     is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
//     then: Yup.string().required("One of the documents is mandatory")
//   })
//   .matches(isSpace,'Spaces are not allowed')
//   .matches(panCardRegExp,'Enter Valid Pan Number'),
//   gstInNumber: Yup.string()
//   .label('GSTIN Number')
//   .min(15)
//   .max(15)
//   .when(["panNumber", "aadharNumber"], {
//     is: (a, b) => (!a || a.length === 0) && (!b || b.length === 0),
//     then: Yup.string().required("One of the documents is mandatory")
//   })
//   .matches(isSpace,'Spaces are not allowed')
//   .matches(isGSTINNumber,'Enter valid GSTIN Number'),

// },[["aadharNumber","gstInNumber"],["aadharNumber","panNumber"],["gstInNumber","panNumber"]]);

export const otpSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .min(10, "Number must have 10 digits")
    .max(10, "Number must have 10 digits")
    .required("Please enter a registered Number")
    .matches(isSpace, "Spaces are not allowed")
    .matches(phoneRegExp, "Please Enter Valid Mobile Number"),
});
export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please Enter Password")
    .matches(isSpace, "Spaces are not allowed"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const bookingSchema = Yup.object().shape({
  firstName: Yup.string()
    .label("First Name")
    .required()
    .min(2, "Must have at least 2 characters")
    .matches(isAlpha, "Only Alphabets are allowed")
    .matches(isSpace, "Spaces are not allowed"),
  lastName: Yup.string()
    .label("Last Name")
    .required()
    .min(2, "Must have at least 2 characters")
    .matches(isAlpha, "Only Alphabets are allowed")
    .matches(isSpace, "Spaces are not allowed"),
  email: Yup.string().label("Email").email("Enter a valid email"),
  aadharCard: Yup.string().label("Aadhar Card").required().min(12).max(12),
  phone: Yup.string()
    .min(10, "Number must have 10 digits")
    .max(10, "Number must have 10 digits")
    .required("Please enter a registered Number")
    .matches(phoneRegExp, "Please Enter Valid Number"),
  foodDeposit: Yup.number().label("Food Deposit").integer().positive(),
  miscDeposit: Yup.number().label("Misc Deposit").integer().positive(),
  depositAmount: Yup.number().label("Rent Deposit").integer().positive(),
  upiID: Yup.string()
    .label("UPI ID")
    .required()
    .matches(isUpiId, "Enter Valid UPI ID")
    .matches(isSpace, "Spaces are not allowed"),
  amountPaid: Yup.number().label("Amount").required().integer().moreThan(0),
});

export const changeSponsorSchema = Yup.object().shape({
  firstName: Yup.string()
    .label("First Name")
    .required()
    .min(2, "Must have at least 2 characters"),
  lastName: Yup.string()
    .label("Last Name")
    .required()
    .min(2, "Must have at least 2 characters"),
  email: Yup.string().label("Email").email("Enter a valid email"),
  aadharCard: Yup.string().label("Aadhar Card").required().min(12).max(12),
  phone: Yup.string()
    .min(10, "Number must have 10 digits")
    .max(10, "Number must have 10 digits")
    .required("Please enter a registered Number")
    .matches(phoneRegExp, "Please Enter Valid Number"),
  upiID: Yup.string().label("UPI ID").required(),
});

export const personalDetailSchema = Yup.object().shape({
  firstName: Yup.string()
    .label("First Name")
    .required()
    .min(1, "Must have at least 2 characters")
    .matches(isAlpha, "Only Alphabets are allowed")
    .matches(isSpace, "Spaces are not allowed"),
  lastName: Yup.string()
    .label("Last Name")
    .required()
    .min(1, "Must have at least 2 characters")
    .matches(isAlpha, "Only Alphabets are allowed")
    .matches(isSpace, "Spaces are not allowed"),
  aadharNumber: Yup.string()
    .label("Aadhar Card Number")
    .min(12)
    .max(12)
    .matches(isSpace, "Spaces are not allowed")
    .matches(aadharCardRegExp, "Enter Valid Aadhar Number"),
  panNumber: Yup.string()
    .label("Pan Card Number")
    .min(10)
    .max(10)
    .matches(isSpace, "Spaces are not allowed")
    .matches(panCardRegExp, "Enter Valid Pan Number"),
  gstInNumber: Yup.string()
    .label("GSTIN Number")
    .min(15)
    .max(15)
    .matches(isSpace, "Spaces are not allowed")
    .matches(isGSTINNumber, "Enter valid GSTIN Number"),

  password: Yup.string()
    .required("Please Enter Password")
    .matches(isSpace, "Spaces are not allowed"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  email: Yup.string().notRequired().email("Enter valid email address"),
  address: Yup.string()
    .required("Address is mandatory")
    .max(180, "Can't add morethan 180 characters"),
  // mobileNumber: Yup.string()
  // .min(10,'Number must have 10 digits')
  // .max(10,'Number must have 10 digits')
  // .required('Please enter a mobile Number')
  // .matches(isSpace,'Spaces are not allowed')
  // .matches(phoneRegExp,"Please enter valid mobile number"),
});

export const jobPostSchema1 = Yup.object().shape({
  jobCity: Yup.string().required("Job City is required"),
  jobRole: Yup.string().required("Job Role is required"),
  hiringFor: Yup.string().required("Hiring For is required"),
  address: Yup.string().required("Address is required"),
  minSalary: Yup.string().when("selectedPayBy", {
    is: (fieldTwo) => fieldTwo.value === "range",
    then: Yup.string().required("Please enter min salary amount"),
  }),
  maxSalary: Yup.string().when("selectedPayBy", {
    is: (fieldTwo) => fieldTwo.value === "range",
    then: Yup.string().required("Please enter max salary amount"),
  }),

  numbersOfOpening: Yup.string().required("NumberOfOpenings is required"),
  exactSalary: Yup.string().when("selectedPayBy", {
    is: (fieldTwo) => fieldTwo.value === "exact amount",
    then: Yup.string().required("Please enter exact salary amount"),
  }),
  selectedPayBy: Yup.object().shape({
    value: Yup.string().required("Please select anyone option"),
    label: Yup.string().required(),
  }),
});
export const jobPostSchema3 = Yup.object().shape({
  address03: Yup.string()
    .required("Job Descrption is required")
    .max(180, "Can't add morethan 180 characters"),
  deadline: Yup.string(),
  dedlineDate: Yup.date().when("deadline", {
    is: (fieldTwo) => fieldTwo === "yes",
    then: Yup.date().required("DedlineDate is required"),
  }),
  personalDetailName: Yup.string()
    .notRequired()
    .min(2, "Must have at least 2 characters")
    .matches(isAlpha, "Only Alphabets are allowed"),
  mobileNumber: Yup.string()
    .notRequired()
    .min(10, "Number must have 10 digits")
    .max(10, "Number must have 10 digits")
    .matches(isSpace, "Spaces are not allowed")
    .matches(phoneRegExp, "Please Enter Valid Mobile Number"),
  email: Yup.string().notRequired().email("Enter valid email address"),
});
export const jobPostSchema2 = Yup.object().shape({
  education: Yup.object().shape({
    value: Yup.string().required("Please select anyone option"),
    label: Yup.string().required(),
  }),
  jobShift: Yup.object().shape({
    value: Yup.string().required("Please select anyone option"),
    label: Yup.string().required(),
  }),
  jobTime: Yup.object().shape({
    value: Yup.string().required("Please select anyone option"),
    label: Yup.string().required(),
  }),
  experience: Yup.string().when("jobExp", {
    is: (fieldTwo) => fieldTwo === "experienced",
    then: Yup.string().required("experience is required"),
  }),
});

export const eduSkill = Yup.object().shape({
  education: Yup.string()
    .required("Please Select Education")
    .label("Education"),
  degreeName: Yup.string()
    .label("Degree Name")
    .min(1, "Must have at least 1 characters"),
  collegeName: Yup.string()
    .label("College Name")
    .min(1, "Must have at least 1 characters"),
});
export const workExperience = Yup.object().shape({
  jobTitle: Yup.string()
    .label("Job Title")
    .required("Please enter job title")
    .min(1, "Must have at least 1 characters"),
  companyName: Yup.string()
    .required("Please enter company/employer name")
    .label("Company Name")
    .min(1, "Must have at least 1 characters"),
  salary: Yup.string()
    .label("Salary")
    .notRequired()
    .min(1, "Must have at least 1 characters"),
  yearsOfExperience: Yup.string()
    .label("Experience")
    .required("Please select experience level")
    .min(1, "Must have at least 1 characters"),
});

export const checkInFilterSchema = Yup.object().shape(
  {
    mobileNumber: Yup.string()
      .min(10, "Number must have 10 digits")
      .max(10, "Number must have 10 digits")
      // .required('Please enter a registered Number')
      .matches(phoneRegExp, "Please Enter Valid Number")
      .when("bookingId", {
        is: (fieldTwo) => !fieldTwo || fieldTwo.length === 0,
        then: Yup.string().required("At least one of the fields is required"),
        otherwise: Yup.string(),
      }),
    bookingId: Yup.string()
      .label("Booking Id")
      .min(8)
      .max(8)
      .when("mobileNumber", {
        is: (fieldTwo) => !fieldTwo || fieldTwo.length === 0,
        then: Yup.string().required("At least one of the fields is required"),
      }),
  },
  ["mobileNumber", "bookingId"]
);
