import React from "react";
import { Row, Col } from "reactstrap";

//Import Images
import logolight from "../../../../assets/images/logos/numerolution-Logo-white.png";

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="4">
          <div>
            <img src={logolight} alt="" height="100" />
          </div>
        </Col>
        <Col lg="8" className="text-center align-self-center">
          <p className="mb-2">
            {new Date().getFullYear()} © Numerolution. All Rights Reserved
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FooterLink;
