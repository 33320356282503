import React from "react";
import { Container, Row, Col } from "reactstrap";

export const Engagement = () => {
  document.title = "Numerolution | Engagement and Marriage date recommendation";
  return (
    <React.Fragment>
      <section
        style={{
          background:
            "url(https://www.numerolution.com/wp-content/uploads/2022/08/32669ed44c26bcb06e66af1d26337acb-scaled.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="bg-ico-hero"
      >
        <Container
          className="section hero-section max-full"
          style={{
            backdropFilter: "brightness(0.3)",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">
              ENGAGEMENT AND MARRIAGE DATE RECOMMENDATION
            </h1>
            <br />
            <p className="font-size-18">
              Best Tips for Wedding Date planning or Engagement date
              recommendation is very important in your life as it is the date
              which would remain evergreen memory for you, most of us don’t know
              that it is the very important date for any couple not only because
              of memory that stays with you forever but the effects of planetary
              actions that would leave forever impact on your life as a couple
              so guys don’t take chance just get our recommendation on the same
              via live appointment.
            </p>
          </div>
        </Container>
      </section>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div
          className="row text-center"
          style={{ marginTop: "-15%", marginBottom: "5%" }}
        >
          <img
            style={{ boxShadow: "0px 0px 10px 5px #ffc8c8" }}
            className="rounded-5 p-0"
            src="https://www.numerolution.com/wp-content/uploads/2020/06/5ef47fb2e92ff-1.jpg"
          />
        </div>
        <div className="row">
          <h1 className="text-light-green px-2 py-4 text-center">
            UNDERSTAND & IMPROVE
          </h1>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object@3x.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Importance Of Happy Marriage Life</h3>
              <p>
                The date when you and your soul mate become one and begin your
                life’s journey as one is unique and has great influence on your
                life , and the date plays the king role in deciding your journey
                with your life partner , We highly recommend you to contact us
                if you are thinking to get a marriage date, we have thousands of
                proven examples of successful marriage life when decided
                marriage date according to their birthdate , also it is possible
                that before marriage life couple would have been facing problems
                in their life. However, after Tips for successful marriage life
                and successful marriage date your life become change and right
                now many couple are thanking our numerology team for bringing
                happiness in their life .
              </p>
            </div>
          </div>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/06/vector-smart-object-2@3x-1.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">
                Necessity Of Perfect Engagement Date
              </h3>
              <p>
                In the same way to get engagement date as per numerology is also
                very important because most difficult time or most time when
                couple needed to be careful is the time between their marriage
                and engagement so it is very necessary for the boy and girl to
                get their birth date solve through engagement date in order to
                lead the perfect life ahead , and it is very necessary for
                couple to take all precautions about their marriage life before
                they get married as it’s the question of their life so don’t
                take chance just book the appointment at first glance .
              </p>
            </div>
          </div>
        </div>
        {/* team */}
        <div className="py-5">
          <Container className="px-4">
            <h1 className="text-light-green text-center mb-4">
              UNDERSTANDING FOREIGN SETTLEMENT
            </h1>
            <Row className="align-items-center pt-4">
              <Col md="6" sm="8">
                <div>
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/marriage-fb_020419062152-1-1024x683-1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto text-mid">
                <h2 className="text-info mb-3 mt-3">Don’t take chance</h2>
                <p>
                  So As per we said earlier it’s the matter of your life then
                  don’t take chance just book the appointment at first glance
                  and get recommendation from our famous numerologist Mr.Parth
                  Patel on ZOOM , or SKYPE and he will guide by providing proper
                  knowledge on why the marriage date as well as engagement date
                  is very important in your life , so don’t take chance and book
                  the appointment with Mr.Parth Patel right now , there are
                  thousands of couple who got marry as per the teasm
                  numerolution’s date recommendation and they are leading very
                  successful life right now .
                </p>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5">
              <Col md="5">
                <h2 className="text-info mb-3">
                  When the matter is all about your life’s happiness
                </h2>
                <p>
                  Engagement date is also the same way important for the couple
                  willing to be together forever , as most of couple suffer a
                  lot due to poor birthdate of engagement date as our team will
                  identify the poor number energy area from your birthdate and
                  we do our own mathematics to provide you natural energy by
                  giving you proper engagement date to initiate this life
                  together as one , so you just need to click on book
                  appointment section to know more regarding your engagement
                  date recommendation as our numero specialist Mr.Parth Patel is
                  all set to help you out in your important decision of your
                  life.
                </p>
              </Col>
              <Col md="6" sm="8" className="ms-md-auto">
                <div className="mt-4 me-md-0">
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/5ef9b36322208-1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
            </Row>
            <h1 className="text-light-green text-center mb-4 mt-5">
              BENEFITS OF TAKING MARRIAGE AND ENGAGEMENT DATE THROUGH NUMEROLOGY
            </h1>
            <div className="px-lg-5 py-lg-4">
              <ul style={{ listStyle: "none" }} className="px-2">
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Have happy and fruitful life with family and your life partner
                  by doing marriage as per numerology through your birth date
                  numbers.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  1500 year old mathematical technology based result through
                  yours and your partner’s birth date calculations.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get multiple options for marriage or engagement date. We have
                  best wedding dates 2020 - 2021.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get couple’s birth date’s absence numbers cured on the
                  marriage date as it would be an ultimate chance for the couple
                  to solve the issues they face because of their birth date
                  before their marriage.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Over 500 plus of marriage and engagement dates of happy and
                  peaceful couples have been decided by our team through
                  numerology so far.
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
