import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import logoWhite from "../../../../assets/images/logos/numerolution-Logo-white.png";
import logoBlack from "../../../../assets/images/logos/numerolution-Logo-black.png";

const navItems = [
  { id: 1, idnm: "home", navheading: "Home" },
  { id: 2, idnm: "about", navheading: "About Us" },
  { id: 3, idnm: "contact-us", navheading: "Contact Us" },
  { id: 3, idnm: "shop", navheading: "Shop" },
  { id: 4, idnm: "book-appointment", navheading: "Book an Appointment" },
];

const shopList = [
  { label: "Home Vastu", hash: "home-vastu" },
  { label: "Signature Analysis", hash: "signature-analysis" },
  { label: "Foreign Settlement", hash: "foreign-settlement" },
  {
    label: "Love And Relationship Solutions",
    hash: "love-relationship-solutions",
  },
  {
    label: "Engagement and Marriage date recommendation",
    hash: "engagement-and-marriage-date-recommendation",
  },
  {
    label: "Career counseling / Job and Business Recommendation",
    hash: "career-counseling-job-and-business-recommendation",
  },
];

const Navbar_Page = (props) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const currentPath = window.location.hash;
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        let scrollup = document.documentElement.scrollTop;
        if (scrollup > 80) {
          setToggleDropdown(false);
        }
      },
      true
    );
  }, []);

  return (
    <React.Fragment>
      <nav
        id="navbar"
        className={
          "navbar navbar-expand-lg navigation fixed-top sticky " +
          props.navClass
        }
      >
        <Container>
          <Link className="navbar-logo p-2" to="/">
            <img
              src={logoBlack}
              alt="numerolution"
              height="30"
              className="logo logo-dark"
            />
            <img
              src={logoWhite}
              alt="numerolution"
              height="100"
              className="logo logo-light"
            />
          </Link>
          <div className="d-flex flex-row align-items-center">
            <button
              onClick={() => {
                return props.history.push("/shop/cart");
              }}
              type="button"
              className={
                "btn header-item noti-icon right-bar-toggle d-lg-none d-sm-block"
              }
            >
              <i
                className={
                  props?.cart?.length > 0
                    ? "bx bx-cart bx-tada nav-link"
                    : "bx bx-cart nav-link"
                }
                style={{ color: "#555b6d" }}
              />
              {props?.cart?.length > 0 ? (
                <span
                  className="badge bg-success rounded-pill"
                  style={{ fontSize: "0.75rem" }}
                >
                  {props?.cart?.length}
                </span>
              ) : null}
            </button>
            <NavbarToggler
              className="p-0"
              onClick={() => {
                setisOpenMenu(!isOpenMenu);
              }}
            >
              <i className="fa fa-fw fa-bars" />
            </NavbarToggler>
            <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
              <Nav className="ms-auto navbar-nav" id="topnav-menu">
                {navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    className={item.navheading === "Home" ? "active" : ""}
                  >
                    <NavLink
                      onClick={() => {
                        setisOpenMenu(!isOpenMenu);
                      }}
                      className={
                        currentPath === "#/" + item?.idnm
                          ? window.innerWidth > 800 && props?.imglight
                            ? "text-white"
                            : "text-primary"
                          : ""
                      }
                      href={"#/" + item.idnm}
                    >
                      {item.navheading}
                    </NavLink>
                  </NavItem>
                ))}
                <Dropdown
                  nav
                  isOpen={toggleDropdown}
                  toggle={() => setToggleDropdown(!toggleDropdown)}
                >
                  <DropdownToggle
                    className={
                      shopList?.find((item) => "#/" + item.hash === currentPath)
                        ? props?.imglight && window.innerHeight > 600
                          ? "text-white"
                          : "text-primary"
                        : ""
                    }
                    nav
                  >
                    Features
                    <i
                      style={{ marginLeft: "10px" }}
                      className="fas fa-angle-down"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {shopList?.map((item, key) => (
                      <DropdownItem className="p-0" key={key}>
                        <NavLink
                          className={
                            currentPath === "#/" + item?.hash
                              ? "text-primary dropdown-text"
                              : "dropdown-text"
                          }
                          href={"#/" + item?.hash}
                        >
                          {item?.label}
                        </NavLink>
                      </DropdownItem>
                    ))}
                    {/* <DropdownItem divider /> */}
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
            <button
              onClick={() => {
                props.history.push("/shop/cart");
              }}
              type="button"
              className={"btn header-item noti-icon d-none d-lg-block nav-item"}
            >
              <i
                className={
                  props?.cart?.length > 0
                    ? "bx bx-cart bx-tada bx-md nav-link"
                    : "bx bx-cart bx-md nav-link"
                }
                style={{
                  color: props?.imglight
                    ? "rgba(255, 255, 255, 0.6)"
                    : "#555b6d",
                }}
              />
              {props?.cart?.length > 0 ? (
                <span
                  className="badge bg-success rounded-pill"
                  style={{ fontSize: "0.8rem" }}
                >
                  {props?.cart?.length}
                </span>
              ) : null}
            </button>
          </div>
        </Container>
      </nav>
    </React.Fragment>
  );
};

Navbar_Page.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string,
};

export default Navbar_Page;
