import React from "react";
import { Container, Row, Col, NavLink } from "reactstrap";

const Features = () => {
  return (
    <React.Fragment>
      <Container className="font-size-16 px-4">
        <Row>
          <Col lg="12">
            <div
              style={{ backgroundColor: "#e8ecff" }}
              className="text-center mb-3 mt-5 border border-primary rounded-3"
            >
              <h1 className="p-2 m-0">Our Sevices</h1>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center pt-4">
          <Col md="6" sm="8">
            <div>
              <img
                src="https://www.numerolution.com/wp-content/uploads/2020/07/5f06f3df3bd0b.jpg"
                alt=""
                className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
              />
            </div>
          </Col>
          <Col md="5" className="ms-auto text-secondary">
            <h2 className="text-primary mb-3 mt-3">Foreign Settlement</h2>
            <p>
              In one of most prominent and accurate services of Numerolution.com
              , we provide efficient and perfect guidance on foreign YOG as well
              as smooth settlement for you , take appointment of our popular
              problem solver and perfect decision maker Mr.Parth Patel and
              Jaykumar Suthar to take the decision of your life right now. Shoot
              down your AIM and lets lead towards an enormous future take an
              appointment today by clicking on appointment section.
            </p>
            <NavLink className="text-primary" href="#/foreign-settlement">
              READ MORE &nbsp;
              <i className="mdi font-size-18 mdi-chevron-right-circle" />
            </NavLink>
          </Col>
        </Row>

        <Row className="align-items-center mt-5 pt-md-5">
          <Col md="5">
            <h2 className="text-primary mb-3">Signature Analysis</h2>
            <p>
              Our signature or our identification determines the course of life
              in this birth, and the subsequent actions which can either lead to
              liberation or re-birth. There are many ways to sign a name, which
              bears many results, like improves popularity, success, fame,
              financial gains, win over enemies etc.. Different styles of
              signatures yield different results. But i am going to discuss only
              the best and ideal way to sign your name, which is ideally suited
              to
            </p>
            <NavLink className="text-primary" href="#/signature-analysis">
              READ MORE &nbsp;
              <i className="mdi font-size-18 mdi-chevron-right-circle" />
            </NavLink>
          </Col>
          <Col md="6" sm="8" className="ms-md-auto">
            <div className="mt-4 me-md-0">
              <img
                src="https://www.numerolution.com/wp-content/uploads/2020/06/trump_signature_analysis_1050x700.png"
                alt=""
                className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
              />
            </div>
          </Col>
        </Row>
        <Row className="align-items-center pt-4">
          <Col md="6" sm="8">
            <div>
              <img
                src="https://www.numerolution.com/wp-content/uploads/2020/06/Faculty-research-women-in-management-640x480-1.jpg"
                alt=""
                className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
              />
            </div>
          </Col>
          <Col md="5" className="ms-auto">
            <h2 className="text-primary mb-3 mt-3">
              Career guidance / Business and Job Recommendation
            </h2>
            <p>
              This is the important and most common problem to choose the right
              career, because right career can bring you to the top and wrong
              can bring you to the bottom, so better we choose our career
              according to the numerology method based on your birth date. We
              are living in the world of magic, because when our great
              cricketers who are earning millions right now would have been
              earning less than 20000 a month if they would have preferred to
              choose laundry business instead of playing cricket in short it is
              necessary to be into right profession in the right time.
            </p>
            <NavLink
              className="text-primary"
              href="#/career-counseling-job-and-business-recommendation"
            >
              READ MORE &nbsp;
              <i className="mdi font-size-18 mdi-chevron-right-circle" />
            </NavLink>
          </Col>
        </Row>
        <Row className="align-items-center mt-5 pt-md-5">
          <Col md="5">
            <h2 className="text-primary mb-3">Home vastu</h2>
            <p>
              Entire Universe is an Integrated circuit that contains all the
              functions. It works through the cosmic web of 27 – Nakshatras
              (constellations),12 Rashi (zodiacs), 9 planets, our mind, Body,
              heart and spirit and the tiny particles of Earth. All of them are
              well connected with one omnipresent common link called “The
              central Axis”.Pyramids vastu is a superb way that can produce
              satisfactory result at all three levels of vastu. So let’s learn
              this pyramid vastu step by step to resolve numerous problems in
              the path of life. Pyramid vasstu is an art and science of managing
              the beneficial current of vastu is that it makes use of all three
              power- power of Particle kingdom, Power of your own Heart-mind and
              Power of the almighty. Pyramids vastu is a science, spirituality
              and consciousness “combo” to use all three together.
            </p>
            <NavLink className="text-primary" href="#/home-vastu">
              READ MORE &nbsp;
              <i className="mdi font-size-18 mdi-chevron-right-circle" />
            </NavLink>
          </Col>
          <Col md="6" sm="8" className="ms-md-auto">
            <div className="mt-4 me-md-0">
              <img
                src="https://www.numerolution.com/wp-content/uploads/2020/07/Akshardham-Temple-2-720x480-1.jpg"
                alt=""
                className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
              />
            </div>
          </Col>
        </Row>
        <Row className="align-items-center pt-4">
          <Col md="6" sm="8">
            <div>
              <img
                src="https://www.numerolution.com/wp-content/uploads/2022/08/Love-1024x768.jpg"
                alt=""
                className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
              />
            </div>
          </Col>
          <Col md="5" className="ms-auto">
            <h2 className="text-primary mb-3 mt-3">
              Love and Relationship solution
            </h2>
            <p>
              The most important factor about your life is your love life
              because little conflicts are part of your life but those conflicts
              should be limited to some extent only and conflicts may be part of
              life but it should not be heart of life, but only wearer knows the
              pain where the shoe pinches, just like that we also let the
              conflict win over relation which is wrong, however don’t be sad as
              we are here and we would provide the best solution available for
              your life.
            </p>
            <NavLink
              className="text-primary"
              href="#/love-relationship-solutions"
            >
              READ MORE &nbsp;
              <i className="mdi font-size-18 mdi-chevron-right-circle" />
            </NavLink>
          </Col>
        </Row>
        <Row className="align-items-center mt-5 pt-md-5">
          <Col md="5">
            <h2 className="text-primary mb-3">
              Engagement and Marriage date recommendation
            </h2>
            <p>
              Marriage date or engagement date is very important in your life as
              it is the date which would remain evergreen memory for you, most
              of us don’t know that it is the very important date for any couple
              not only because of memory that stays with you forever but the
              effects of planetary actions that would leave forever impact on
              your life as a couple so guys don’t take chance just get our
              recommendation on the same via live appointment.
            </p>
            <NavLink
              className="text-primary"
              href="#/career-counseling-job-and-business-recommendation"
            >
              READ MORE &nbsp;
              <i className="mdi font-size-18 mdi-chevron-right-circle" />
            </NavLink>
          </Col>
          <Col md="6" sm="8" className="ms-md-auto">
            <div className="mt-4 me-md-0">
              <img
                src="https://www.numerolution.com/wp-content/uploads/2020/06/marriage-fb_020419062152-1.jpg"
                alt=""
                className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Features;
