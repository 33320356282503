import PropTypes from "prop-types";
import React, { useState } from "react";

import { CardBody, Collapse } from "reactstrap";
import { Link } from "react-router-dom";

const Accordian = (props) => {
  const [activeIndex, setActiveIndex] = useState(false);
  if (!props?.questionAnswers?.length) return null;
  return (
    <React.Fragment>
      <div>
        <div id="gen-ques-accordion" className="accordion custom-accordion">
          {props?.questionAnswers?.map((item, index) => {
            return (
              <div className="mb-3" key={index}>
                <Link
                  to="#"
                  className="accordion-list"
                  onClick={() => {
                    if (activeIndex === index) {
                      setActiveIndex(false);
                    } else {
                      setActiveIndex(index);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="text-primary">{item?.question}</div>
                  <i
                    className={
                      index === activeIndex
                        ? "mdi mdi-minus accor-plus-icon"
                        : "mdi mdi-plus accor-plus-icon"
                    }
                  />
                </Link>

                <Collapse isOpen={index === activeIndex}>
                  <CardBody>
                    <div className="mb-0">{item?.answer}</div>
                  </CardBody>
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

Accordian.propTypes = {
  questionAnswers: PropTypes.array.isRequired,
};

export default Accordian;
