import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Input,
  CardTitle,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Product Images
import img1 from "assets/images/product/img-1.png";
import img2 from "assets/images/product/img-2.png";
import img3 from "assets/images/product/img-3.png";
import img4 from "assets/images/product/img-4.png";
import img5 from "assets/images/product/img-5.png";
import img6 from "assets/images/product/img-6.png";

import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { Constants } from "helpers/constant";
import constant from "service/constant";
import { isEmpty } from "lodash";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
const Cart = (props) => {
  //meta title
  document.title = "Cart | Numerolution";
  const currencyString = sessionStorage.getItem("currencyString") ?? "$";
  const currencySymbol = sessionStorage.getItem("currencySymbol") ?? "$";
  const [grandTotalAmount, setGrandTotal] = useState();
  const { cart, updateCart, removeFromCart } = props;
  function grandTotal() {
    let sum = 0;

    cart.forEach((item) => {
      sum += item.productTotal;
    });
    return sum;
  }
  useEffect(() => {
    let total = grandTotal();
    setGrandTotal(total);
  }, [cart]);

  function removeCartItem(id) {
    removeFromCart(id);
  }

  function countUP(id, prev_data_attr) {
    updateCart(
      cart.map((p) =>
        p._id === id
          ? {
              ...p,
              quantity: prev_data_attr + 1,
              productTotal: Number(p.price) * (prev_data_attr + 1),
            }
          : p
      )
    );
  }

  function countDown(id, prev_data_attr) {
    if (prev_data_attr === 1) return;
    updateCart(
      cart.map((p) =>
        p._id === id
          ? {
              ...p,
              quantity: prev_data_attr - 1,
              productTotal: Number(p.price) * Number(prev_data_attr - 1),
            }
          : p
      )
    );
  }

  return (
    <React.Fragment>
      <section
        className="section hero-section"
        style={{
          backgroundColor: "#131521",
          // backgroundImage: `url(${BackgroundImageSecondary})`,
          backgroundPosition: "top",
          backgroundRepeat: "repeat",
        }}
      >
        <Container fluid>
          <Row>
            <Col lx={"8"}>
              <Card className={cart.length <= 0 ? "bg-transparent" : ""}>
                <CardBody>
                  {cart?.length > 0 ? (
                    <div className="table-responsive">
                      <Table className="table align-middle mb-0 table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>Product</th>
                            <th>Product Desc</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th colSpan="2">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cart.map((product) => (
                            <tr key={product._id}>
                              <td>
                                <img
                                  src={
                                    constant.IMG_URL +
                                    product?.productImg?.imagePath
                                  }
                                  alt="product-img"
                                  title="product-img"
                                  className="avatar-md"
                                />
                              </td>
                              <td>
                                <h5 className="font-size-14 text-truncate">
                                  <Link
                                    to={"/shop/product-details/" + product._id}
                                    className="text-dark"
                                  >
                                    {product.productName}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {currencySymbol ?? currencyString}{" "}
                                  {product?.price} x {product?.quantity}
                                </p>
                              </td>
                              <td>
                                {currencySymbol ?? currencyString}{" "}
                                {product.price}
                              </td>
                              <td>
                                <div style={{ width: "120px" }}>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          countUP(
                                            product._id,
                                            product.quantity
                                          );
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                    <Input
                                      type="text"
                                      className="text-center"
                                      value={product.quantity}
                                      name="demo_vertical"
                                      readOnly
                                    />
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          countDown(
                                            product._id,
                                            product.quantity
                                          );
                                        }}
                                      >
                                        -
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {currencySymbol ?? currencyString}{" "}
                                {product.productTotal}
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  onClick={() => removeCartItem(product)}
                                  className="action-icon text-danger"
                                >
                                  {" "}
                                  <i className="mdi mdi-trash-can font-size-18" />
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center flex-column justify-content-center text-center">
                      <h3 className="pb-2 text-center w-100 fw-bold text-white text-opacity-75 d-flex align-items-center flex-row justify-content-center gap-1">
                        <i
                          class="mdi mdi-cart-remove"
                          style={{ fontSize: "4rem" }}
                        />
                        Your cart is empty !
                      </h3>
                      <Button
                        size="lg"
                        color="success"
                        className="w-lg fw-bold mt-2 d-inline-flex justify-content-center align-items-center gap-2 text-opacity-75 bx-flashing-hover"
                        outline
                        onClick={() => props.history.push("/shop")}
                      >
                        {"Shop now >>>"}
                      </Button>
                    </div>
                  )}
                  <Row className="mt-4" hidden={cart.length <= 0}>
                    <Col sm="6">
                      <Link to="/shop" className="btn btn-secondary">
                        <i className="mdi mdi-arrow-left me-1" /> Continue
                        Shopping{" "}
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" hidden={cart.length <= 0}>
              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">Card Details</CardTitle>

                  <div className="card bg-primary text-white visa-card mb-0">
                    <CardBody>
                      <div>
                        <i className="bx bxl-visa visa-pattern" />

                        <div className="float-end">
                          <i className="bx bxl-visa visa-logo display-3" />
                        </div>

                        <div>
                          <i className="bx bx-chip h1 text-warning" />
                        </div>
                      </div>

                      <Row className="mt-5">
                        <Col xs="4">
                          <p>
                            <i className="fas fa-star-of-life m-1" />
                            <i className="fas fa-star-of-life m-1" />
                            <i className="fas fa-star-of-life m-1" />
                          </p>
                        </Col>
                        <Col xs="4">
                          <p>
                            <i className="fas fa-star-of-life m-1" />
                            <i className="fas fa-star-of-life m-1" />
                            <i className="fas fa-star-of-life m-1" />
                          </p>
                        </Col>
                        <Col xs="4">
                          <p>
                            <i className="fas fa-star-of-life m-1" />
                            <i className="fas fa-star-of-life m-1" />
                            <i className="fas fa-star-of-life m-1" />
                          </p>
                        </Col>
                      </Row>

                      <div className="mt-5">
                        <h5 className="text-white float-end mb-0">12/22</h5>
                        <h5 className="text-white mb-0">Fredrick Taylor</h5>
                      </div>
                    </CardBody>
                  </div>
                </CardBody>
              </Card> */}
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Order Summary</CardTitle>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>Grand Total :</td>
                          <td>
                            {grandTotalAmount} &nbsp;
                            <strong>{currencyString}</strong>
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Discount : </td>
                          <td>- &nbsp;<strong>{currencyString}</strong> 157</td>
                        </tr> */}
                        {/* <tr>
                                                    <td>Shipping Charge :</td>
                                                    <td>&nbsp;<strong>{currencyString}</strong> 0</td>
                                                </tr> */}
                        {/* <tr>
                          <td>Estimated Tax : </td>
                          <td>&nbsp;<strong>{currencyString}</strong> 19.22</td>
                        </tr> */}
                        <tr>
                          <th>Total :</th>
                          <th>
                            {grandTotalAmount} &nbsp;
                            <strong>{currencyString}</strong>
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="text-sm-end mt-3">
                    <Link
                      to={{
                        pathname: "/shop/checkout",
                        state: { orderObj: cart },
                      }}
                      className="btn btn-success"
                    >
                      <i className="mdi mdi-cart-arrow-right me-1" /> Checkout{" "}
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cart;
