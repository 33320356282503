export const options = {
  salary: [
    { value: "range", label: "Range", name: "selectedPayBy" },
    { value: "exact amount", label: "Exact amount", name: "selectedPayBy" },
  ],
  education: [
    { value: "10th", label: "10th pass", name: "education" },
    { value: "12th", label: "12th pass", name: "education" },
    { value: "diploma", label: "Diploma", name: "education" },
    { value: "be-btech", label: "B.E /B-Tech", name: "education" },
    { value: "me-mtech", label: "M.E /M-Tech", name: "education" },
  ],
  jobShift: [
    { value: "morning", label: "Morning shift", name: "jobShift" },
    { value: "night", label: "Night Shift", name: "jobShift" },
    { value: "day-night", label: "Day-Night Shift", name: "jobShift" },
  ],
  jobTime: [
    { value: "part-time", label: "Part Time", name: "jobTime" },
    { value: "full-time", label: "Full Shift", name: "jobTime" },
  ],
  washRoomType: [
    { value: "Attach", label: "Attach", name: "washRoomType" },
    { value: "Common", label: "Common", name: "washRoomType" },
    { value: "Attach+Common", label: "Attach+Common", name: "washRoomType" },
  ],

  food: [
    { value: "Extra", label: "Extra", name: "food" },
    { value: "Included", label: "Included", name: "food" },
  ],
  gender: [
    { value: "male", label: "Male", name: "gender" },
    { value: "female", label: "Female", name: "gender" },
  ],
  roomType: [
    { value: "pod", label: "POD", name: "roomType" },
    { value: "room", label: "Room", name: "roomType" },
  ],
  electricity: [
    { value: "Extra", label: "Extra", name: "electricity" },
    { value: "Included", label: "Included", name: "electricity" },
  ],
  internet: [
    { value: "Extra", label: "Extra", name: "internet" },
    { value: "Included", label: "Included", name: "internet" },
  ],
  tax: [
    { value: "Extra", label: "Extra", name: "internet" },
    { value: "Included", label: "Included", name: "internet" },
  ],
  unitType: [
    {
      value: "Dorm",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>Dorm</span>
          <i
            className="fas fa-info-circle"
            title="A large sleeping room containing several beds"
          />
        </>
      ),
      name: "unitType",
    },
    {
      value: "Studio",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>Studio</span>
          <i
            className="fas fa-info-circle"
            title="A small apartment consisting typically of a main room, kitchenette, and bathroom"
          />
        </>
      ),
      name: "unitType",
    },
    {
      value: "Room Without Kitchen",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>Room Without Kitchen</span>
          <i className="fas fa-info-circle" title="Room Without Kitchen" />
        </>
      ),
      name: "unitType",
    },
    {
      value: "RK",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>Room With Kitchen</span>
          <i className="fas fa-info-circle" title="Room With Kitchen" />
        </>
      ),
      name: "unitType",
    },
    {
      value: "1BHK",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>1BHK</span>
          <i
            className="fas fa-info-circle"
            title="A 1BHK stands for one standard-sized bedroom,a hall, and a kitchen"
          />
        </>
      ),
      name: "unitType",
    },
    {
      value: "2BHK",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>2BHK</span>
          <i
            className="fas fa-info-circle"
            title="A 2BHK stands for two standard-sized bedroom,a hall, and a kitchen"
          />
        </>
      ),
      name: "unitType",
    },
    {
      value: "3BHK",
      label: (
        <>
          <span style={{ paddingRight: "5px" }}>3BHK</span>
          <i
            className="fas fa-info-circle"
            title="A 3BHK stands for three standard-sized bedroom,a hall, and a kitchen"
          />
        </>
      ),
      name: "unitType",
    },
  ],
  arrangement: [
    { value: "Pure Rent", label: "Pure Rent", name: "arrangement" },
    {
      value: "Revenue Share on Rent",
      label: "Revenue Share on Rent",
      name: "arrangement",
    },
    { value: "Min Guarantee", label: "Min Guarantee", name: "arrangement" },
    { value: "PMS", label: "PMS", name: "arrangement" },
    {
      value: "Discount on Invoice",
      label: "Discount on Invoice",
      name: "arrangement",
    },
  ],
  agreementType: [
    { value: "MOU", label: "MOU", name: "agreementType" },
    { value: "Lease Agt", label: "Lease Agt", name: "agreementType" },
    { value: "PMS Agt", label: "PMS Agt", name: "agreementType" },
  ],
  bookingType: [
    { value: "room", label: "Room", name: "bookingType" },
    { value: "pod", label: "POD", name: "bookingType" },
  ],
  imgType: [
    { value: "propertyFront", label: "Property Front", name: "imgType" },
    { value: "propertyBack", label: "Property Back", name: "imgType" },
    { value: "corridor", label: "Corridor", name: "imgType" },
    { value: "room", label: "Room", name: "imgType" },
    { value: "more", label: "More", name: "imgType" },
  ],
  documentType: [
    { value: "panCard", label: "Pan Card", name: "documentType" },
    { value: "aadharCard", label: "Aadhar Card", name: "documentType" },
    { value: "gstIN", label: "GSTIN Number", name: "documentType" },
  ],
};
