import React from "react";
import { Container, Row, Col } from "reactstrap";

const AboutUs = () => {
  return (
    <React.Fragment>
      <div className="section pt-4 bg-white px-4">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1>About us</h1>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="font-size-16">
                <p>
                  We are one of the <strong>Best Numerology Services</strong>{" "}
                  present in web portal as we provide unique and dynamic report
                  in pdf format within seconds, we are providing best numerology
                  services because we have intense knowledge about bracelet
                  therapy and numerological effects on human being, our services
                  are reasonable as well so we are best online numerology
                  solution provider.
                </p>
                <p className="mb-4">
                  Our reports are cost effective and we identify the problems of
                  your life and because of our free yet powerful solutions
                  suggestions we are called as free numerolution services , we
                  also provide, home vastu pyramid, foreign settlement in
                  astrology, numerology signature analysis or you can call
                  signature analysis astrology, love problem and solution
                  through numerology and every solution related to numerology we
                  do so that is what the <a href="#">numerolution.com</a> is all
                  about.
                </p>
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <div className="mt-4 mt-lg-0 overflow-hidden">
                <img
                  className="rounded"
                  style={{ width: "60%" }}
                  src="https://www.numerolution.com/wp-content/uploads/2022/08/business-concept-with-team-close-up-1-1024x1013.jpg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
