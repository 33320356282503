import React from "react";
import AboutUs from "./CryptoIcoLanding/AboutUs/about-us";
import FAQs from "./CryptoIcoLanding/Faqs/FAQs";
import Features from "./CryptoIcoLanding/Features/features";
import Section from "./CryptoIcoLanding/HeroSection/Section";
import { Testimonials } from "./CryptoIcoLanding/Testimonials";
import { WhyUs } from "./CryptoIcoLanding/WhyUs";
import { Contact } from "./CryptoIcoLanding/ContactUs";

export const LandingPage = (props) => {
  document.title = "Home | Numerolution";
  return (
    <React.Fragment>
      <Section {...props} />
      <AboutUs />
      <Contact />
      <WhyUs />
      <Features />
      <Testimonials />
      <FAQs />
    </React.Fragment>
  );
};
