import React from "react";
import { Container, Row, Col } from "reactstrap";

export const ForeignSettlement = () => {
  document.title = "Numerolution | Foreign Settlement";
  return (
    <React.Fragment>
      <section
        style={{
          background:
            "url(https://www.numerolution.com/wp-content/uploads/2022/08/1408399789000-captain-nightlanding.jpg)",
        }}
        className="bg-ico-hero"
      >
        <Container
          className="section hero-section max-full"
          style={{
            backdropFilter: "blur(2px) brightness(0.3)",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">FOREIGN SETTLEMENT</h1>
            <br />
            <p className="font-size-18">
              Foreign settlement is something which is very important for your
              life as it is going to decide how your life would be in future, as
              we are here to suggest you the best date date to file your
              application successfully as well as your date of departure to
              enter the abroad country.
            </p>
          </div>
        </Container>
      </section>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div
          className="row text-center"
          style={{ marginTop: "-15%", marginBottom: "5%" }}
        >
          <img
            style={{ boxShadow: "0px 0px 10px 5px #ffc8c8" }}
            className="rounded-5 p-0"
            src="https://www.numerolution.com/wp-content/uploads/2020/07/VisualsStock_AK61523-1.jpg"
          />
        </div>
        <div className="row">
          <h1 className="text-light-green px-2 py-4 text-center">
            UNDERSTAND & IMPROVE
          </h1>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object@3x.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Superior Assistance</h3>
              <p>
                In one of most prominent and accurate services of Shreeji
                numerology services, we provide efficient and perfect guidance
                on foreign YOG as well as smooth settlement for you; take
                appointment of our popular problem solver and perfect decision
                maker Mr.parth patel and Jaykumar Suthar to take the decision of
                your life right now. Shoot down your AIM and let’s lead towards
                an enormous future take an appointment today by clicking on
                appointment section.
              </p>
            </div>
          </div>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/06/vector-smart-object-2@3x-1.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Bringing Solutions To Your Knees</h3>
              <p>
                As our Thousands of clients experience the shocking and unique
                experience in foreign settlement by solving their birth date
                mystery, one can turn the negative number’s energy into positive
                which makes foreign settlement smooth, also you can know about
                which type of job would bring fortune for you in foreign, we are
                also able to suggest that which day you can departure for
                foreign and which lucky numbers you have when you need them
                while foreign study and settlement.
              </p>
            </div>
          </div>
        </div>
        {/* team */}
        <div className="py-5">
          <Container className="px-4">
            <h1 className="text-light-green text-center mb-4">
              UNDERSTANDING FOREIGN SETTLEMENT
            </h1>
            <Row className="align-items-center pt-4">
              <Col md="6" sm="8">
                <div>
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/1-2.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto text-mid">
                <h2 className="text-info mb-3 mt-3">
                  Popular Expert’s appointment
                </h2>
                <p>
                  Our numero specialist Mr. Parth Patel is able to deliver you
                  exactly the same solution to the problem which is making you
                  worry about your upcoming foreign journey , in most effective
                  and power full expect of our this service is that we can also
                  suggest you proper date for visa filing process as lucky
                  number would bring success to your visa application but it
                  should be according to your birthdate .
                </p>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5">
              <Col md="5">
                <h2 className="text-info mb-3">Why pyramid for home vastu?</h2>
                <p>
                  We have plenty of successful clients who are very much
                  delighted with the services of numerolution.com team and
                  leading a beautiful life of their dreams, so guys never take
                  chance when the matter remain all about your career, just
                  remember us and take appointment by today to enjoy un
                  believable results for your bright future in foreign as time
                  is very important and we should respect it fully. we give
                  permanent foreign settlement in astrology and numerology.
                </p>
              </Col>
              <Col md="6" sm="8" className="ms-md-auto">
                <div className="mt-4 me-md-0">
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/5ef479973d7ec-1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
            </Row>
            <h1 className="text-light-green text-center mb-4 mt-5">
              BENEFITS OF SOLUTION FOR FOREIGN SETTLEMENT THROUGH NUMEROLOGY
            </h1>
            <div className="px-lg-5 py-lg-4">
              <ul style={{ listStyle: "none" }} className="px-2">
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get the perfect visa application filing date based on your
                  birth date.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get your absence numbers cured before you go to foreign for
                  smooth settlement.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Most effective and efficient communication channel.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get expert advice at almost no cost.We have free foreign
                  settlement calculator.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Perfect profession selection advice for foreign settlement. We
                  also give advice for foreign settlement in Vedic astrology.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Which country settlement would bring fortune for you most
                  advice?
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Confidential Skype meeting arrangement with our numerology
                  specialist.
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
