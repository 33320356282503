import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import placholderImage from "assets/images/logo-small-nobg_new.png";
import { ApiServices } from "service/apiServices";
import { GET_ALL_CATEGORY } from "service/url_helper";
import service from "service/constant";

export const CategoryList = (props) => {
  const { history } = props;
  const [allCategories, setCategories] = useState(null);
  const getAllCategory = () => {
    try {
      let uri = GET_ALL_CATEGORY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setCategories(response.result.data);
          }
        })
        .catch((err) => console.log("catch getAllCategory", err));
    } catch (error) {
      console.log("catch getAllCategory", err);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          top: "0",
          height: "190px",
          background: "linear-gradient(180deg, black, #f8f8fb)",
        }}
        className="w-100 position-absolute bg-black"
      />
      <div className="page-content" style={{ marginTop: "5%" }}>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col lg="4" sm="6">
                  <div className="mt-2">
                    <h5>Select Category</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                {!isEmpty(allCategories) &&
                  allCategories?.map((category) => (
                    <Col xl="3" sm="6" key={category._id}>
                      <Card
                        onClick={() =>
                          history.push({
                            pathname: "/shop/products",
                            state: { category },
                          })
                        }
                      >
                        <CardBody>
                          <div className="product-img position-relative">
                            <img
                              src={
                                category?.categoryImage
                                  ? service.IMG_URL + category?.categoryImage
                                  : placholderImage
                              }
                              alt=""
                              className="img-fluid mx-auto d-block w-100"
                              style={{ height: "200px", objectFit: "fill" }}
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <h5 className="mb-3 text-truncate text-capitalize">
                              {category?.categoryName}{" "}
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
