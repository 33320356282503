import React from "react";
// import { Col, Container, Form, Row, FormGroup, Label, Input } from 'reactstrap';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiServices } from "service/apiServices";
import * as url from "service/url_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import IndiaFlag from "assets/images/flags/india-flag.png";
import UKFlag from "assets/images/flags/uk-flag.png";
import USFlag from "assets/images/flags/us-flag.jpg";
import GermanFlag from "assets/images/flags/germany-flag.jpg";
import CanadaFlag from "assets/images/flags/canada-flag.svg";

export const Contact = () => {
  document.title = "Contact Us | Numerolution ";
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      contact: "",
      email: "",
      feedBack: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      contact: Yup.string().required("Please Enter Your Phone Number"),
      email: Yup.string().required("Please Enter Your Email Id"),
      feedBack: Yup.string().required("Please Enter Your FeedBack"),
    }),
    onSubmit: (values, { resetForm }) => {
      let uri = url.SEND_FEEDBACK;
      ApiServices.callServicePostWithBodyData(uri, values).then((response) => {
        if (response.result.type === "success") {
          if (response?.result) {
            //toastr.success(response?.result?.message ? response.result.message : '', "Success")
            const MySwal = withReactContent(Swal);
            MySwal.fire("Feed submitted successfully").then((res) => {
              resetForm();
            });
          } else {
            toastr.error(
              response?.result?.message
                ? response.result.message
                : "Error Occured",
              "Failed"
            );
          }
          // props.history.push({ pathname: "/pdf", state: { user: response?.result?.data, language: validation.values.language } })
        }
      });
    },
  });
  return (
    <React.Fragment>
      <section
        className="section hero-section bg-ico-hero"
        id="home"
        style={{ paddingTop: "11.5vmax" }}
      >
        <Container className="d-flex flex-column gap-3">
          <Row className="align-items-center px-2">
            <div className="text-white mb-5 text-center">
              <h1 className="text-light-green font-weight-semibold mb-3">
                Contact Us
              </h1>
              <h3 className="font-size-18 text-white">
                If you are passing by the moon and stuck in traffic, then don't
                worry, just fill the details and we will call you back.
              </h3>
            </div>
            <Col lg="12" md="12" sm="12" className="ms-lg-auto">
              <Card
                className="mb-0 mt-5 mt-lg-0"
                style={{
                  boxShadow: "0px 0px 10px 2px #62ffca",
                  opacity: "0.95",
                  borderRadius: "10px",
                }}
              >
                <CardBody>
                  <h3 className="font-weight-semibold mb-3 text-primary">
                    Do you have any question?
                  </h3>
                  <div className="mt-4">
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        {/* <h3 className="font-weight-semibold mb-3">
                                                Do you have any question ?
                                            </h3> */}
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Mobile number <sup className="text-danger">*</sup>{" "}
                            </Label>
                            <Input
                              name="contact"
                              placeholder="Mobile number"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.contact || ""}
                              invalid={
                                validation.touched.contact &&
                                validation.errors.contact
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contact &&
                            validation.errors.contact ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Email <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="email"
                              placeholder="Your email"
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Message <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              id="feedBack"
                              type="textarea"
                              className="form-control"
                              rows="2"
                              name="feedBack"
                              placeholder="Enter Your Feedback"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.feedBack}
                              invalid={
                                validation.touched.feedBack &&
                                validation.errors.feedBack
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.feedBack &&
                            validation.errors.feedBack ? (
                              <FormFeedback type="invalid">
                                {validation.errors.feedBack}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-center mt-4">
                        <Button color="info" type="submit">
                          Send Feedback
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card
            className="mb-0 mt-5 mt-lg-0 py-4 px-2"
            style={{
              borderRadius: "10px",
            }}
          >
            <CardBody>
              <div className="row  text-white align-items-center px-2 mb-5">
                <h3 className="mb-3 fs-3 fw-bold text-primary">
                  Contact Details
                </h3>
                <Row className="px-5 justify-content-between">
                  <Col lg="4" md="6" sm="12">
                    <h5 className="text-dark">
                      {" "}
                      <i className="fa fa-th fa-1x me-2" />
                      Hepe Enterprise Pvt. Ltd.
                    </h5>
                  </Col>
                  <Col lg="4" md="6" sm="12">
                    <a
                      className="h5 text-dark btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      <i className="mdi mdi-email me-2" />
                      solution@numerolution.com
                    </a>
                  </Col>
                  <Col lg="4" md="6" sm="12">
                    <a
                      className="h5 text-dark btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="tel"
                      href="tel:+917043329693"
                    >
                      <i className="mdi mdi-cellphone me-2" />
                      +917043329693
                    </a>
                  </Col>
                </Row>
              </div>
              <div className="row  text-white mt-3 align-items-center px-2">
                <h3 className="mb-3 fs-3 fw-bold text-primary">
                  Our Locations
                </h3>
                <Row className="px-5 justify-content-between py-2">
                  <Col lg="5" md="6" sm="12">
                    <h4 className="text-dark">
                      {" "}
                      <img
                        src={IndiaFlag}
                        className="rounded-circle avatar-sm me-2"
                      />{" "}
                      India
                    </h4>
                    <br />
                    <address className="mb-0 text-opacity-75 text-dark">
                      <i className="mdi mdi-map-marker-radius  me-2" /> 908,
                      Ganesh Glory, Gota Ahmedabad, Gujarat, India 382481
                    </address>
                    <br />
                    <a
                      className="h5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      <i className="mdi mdi-email me-2" />
                      parth.patel@numerolution.com
                    </a>
                    <br />
                    <a
                      className="h5 mb-5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="tel"
                      href="tel:+917043329693"
                    >
                      <i className="mdi mdi-cellphone me-2" />
                      +917043329693
                    </a>
                    <br />
                  </Col>
                  <Col lg="5" md="6" sm="12" classNa>
                    <h4 className="text-dark">
                      {" "}
                      <img
                        src={GermanFlag}
                        className="rounded-circle avatar-sm me-2"
                      />{" "}
                      Germany
                    </h4>
                    <br />
                    <address className="mb-0 text-opacity-75 text-dark">
                      <i className="mdi mdi-map-marker-radius  me-2" />
                      Kaiserslautern, Gersweilerweg 7, Hinterhof Pavilion
                      (Germany, Rhineland-Palatinate)
                    </address>
                    <br />
                    <a
                      className="h5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      <i className="mdi mdi-email me-2" />
                      solution@numerolution.com
                    </a>
                    <br />
                    <a
                      className="h5 mb-5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="tel"
                      href="tel:+917043329693"
                    >
                      <i className="mdi mdi-cellphone me-2" />
                    </a>
                    <br />
                  </Col>
                  <Col lg="5" md="6" sm="12">
                    <h4 className="text-dark">
                      {" "}
                      <img
                        src={USFlag}
                        className="rounded-circle avatar-sm me-2"
                      />{" "}
                      US
                    </h4>
                    <br />
                    <address className="mb-0 text-opacity-75 text-dark">
                      <i className="mdi mdi-map-marker-radius  me-2" />
                      188 north lovers’ lane, Franklin in 46131, Indianapolis,
                      United States
                    </address>
                    <br />
                    <a
                      className="h5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      <i className="mdi mdi-email me-2" />
                      solution@numerolution.com
                    </a>
                    <br />
                    <a
                      className="h5 mb-5 text-whdarkite text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="tel"
                      href="tel:+917043329693"
                    >
                      <i className="mdi mdi-cellphone me-2" />
                    </a>
                    <br />
                  </Col>
                  <Col lg="5" md="6" sm="12">
                    <h4 className="text-dark">
                      {" "}
                      <img
                        src={CanadaFlag}
                        className="rounded-circle avatar-sm me-2"
                      />{" "}
                      Canada
                    </h4>
                    <br />
                    <address className="mb-0 text-opacity-75 text-dark">
                      <i className="mdi mdi-map-marker-radius  me-2 mb-2" />
                      70 King Street, London, Ontario, Canada
                    </address>
                    <br />
                    <a
                      className="h5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      <i className="mdi mdi-email me-2" />
                      solution@numerolution.com
                    </a>
                    <br />
                    <a
                      className="h5 mb-5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="tel"
                      href="tel:+917043329693"
                    >
                      <i className="mdi mdi-cellphone me-2" />
                    </a>
                    <br />
                  </Col>
                  <Col lg="5" md="6" sm="12">
                    <h4 className="text-dark ">
                      {" "}
                      <img
                        src={UKFlag}
                        className="rounded-circle avatar-sm me-2"
                      />{" "}
                      UK
                    </h4>
                    <br />
                    <address className="mb-0 text-opacity-75 text-dark">
                      <i className="mdi mdi-map-marker-radius  me-2" />
                      54 Empire house Near Dhamecha Foods Ltd Hayes UB3 1AT
                    </address>
                    <br />
                    <a
                      className="h5 text-dark text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="mail"
                      href="mailto:solution@numerolution.com"
                    >
                      <i className="mdi mdi-email me-2" />
                      solution@numerolution.com
                    </a>
                    <br />
                    <a
                      className="h5 mb-5 text-dark  text-opacity-75 btn-link d-inline-flex align-items-center"
                      target="_blank"
                      type="tel"
                      href="tel:+917043329693"
                    >
                      <i className="mdi mdi-cellphone me-2" />
                    </a>
                    <br />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

          <div className="row  text-white mt-3 align-items-center px-2">
            <h2 className="mb-3 fs-2 fw-bold text-light-green">
              Follow Us and stay Updated with all the News
            </h2>
            <Row className="gap-2">
              <Col className="col-1 ">
                <i className="mdi mdi-facebook bx-md me-2" />
              </Col>
              <Col className="col-1">
                <i className="mdi mdi-twitter bx-md me-2" />
              </Col>
              <Col className="col-1">
                <i className="mdi mdi-google-plus bx-md me-2" />
              </Col>
              <Col className="col-1">
                <i className="mdi mdi-linkedin bx-md me-2" />
              </Col>
              <Col className="col-1">
                <i className="mdi mdi-instagram bx-md me-2" />
              </Col>
              <Col className="col-1">
                <i className="mdi mdi-youtube bx-md me-2" />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
