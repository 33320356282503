import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormFeedback,
} from "reactstrap";
import { FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import classnames from "classnames";

import { useFormik } from "formik";
import { billingInfoSchema } from "service/validationSchema";

import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { Constants } from "helpers/constant";
import constant from "service/constant";
import { isEmpty } from "lodash";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Country, State, City } from "country-state-city";

const animatedComponents = makeAnimated();
const ShopCheckout = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 2000,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  //meta title
  document.title = "Checkout | Numerolution";
  const [activeTab, setactiveTab] = useState("1");
  const [formValidated, setFormValidated] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [shipping, setShipping] = useState(50);
  const [discount, setDiscount] = useState(0);
  // const currencyString = sessionStorage.getItem("currencyString") ?? "$"
  // const currencyCode = sessionStorage.getItem("currencyCode") ?? "USD"
  // const currencySymbol = sessionStorage.getItem("currencySymbol") ?? "$"
  // const phoneCode = sessionStorage.getItem("phoneCode") ?? "+1";
  const countryCode = sessionStorage.getItem("countryCode") ?? "IN";

  const allCountries = Country.getAllCountries();
  const [statesForCountry, setStates] = useState([]);
  const [citiesForState, setCities] = useState([]);
  const [finalOrderData, setFinalOrderData] = useState({});
  const [currencyString, setCurrencyString] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [affiliateUser, setAffiliateUser] = useState({});
  const [codeError, setCodeError] = useState("");

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const orderSummary = props?.location?.state?.orderObj;
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const previewOrder = () => {
    try {
      let uri = url.PREVIEW_ORDER;
      let reqObj = {
        order: orderSummary,
      };
      ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (response?.result?.type === Constants.SUCCESS) {
            setFinalOrderData(response.result.data);
            setSubTotal(
              Number(response.result.data.totalAmount) + Number(shipping)
            );
            setCurrencyCode(response.result.data.currencyCode);
            setCurrencyString(response.result.data.currencyString);
            setCurrencySymbol(response.result.data.currencySymbol);
          }
        })
        .catch((error) => {
          console.log("error =>", error);
        });
    } catch (error) {
      console.log("error => previewOrder", error);
    }
  };
  const onPressPayment = async (orderId) => {
    formik.handleSubmit();
    if (!formValidated) return toggleTab("1");
    if (isEmpty(finalOrderData))
      return toastr.error(
        "Error Placing order contact support",
        "Error Occured"
      );
    localStorage.setItem("cart", []);
    try {
      let uri = url.CREATE_ORDER;
      let reqObj = {
        order: finalOrderData.order,
        totalAmount: subTotal,
        currencyCode: currencyCode,
        shippingCharges: shipping,
        discountedAmount: discount,
        affiliateId: affiliateUser?._id ?? null,
        billing: {
          ...values,
          country: values.country?.name ?? "",
          state: values.state?.name ?? "",
          city: values.city?.name ?? "",
          mobileNumber: String(values.mobileNumber),
          userId: null,
        },
        return_url: `${constant.PAYMENT_URL}/#/shop/order-status/`,
      };
      await ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (response?.result?.type === Constants.SUCCESS) {
            window.location.replace(response.result.payment.payment_link);
          } else {
            toastr.error(
              response?.result?.message
                ? response.result.message
                : "Error Occured",
              "Failed"
            );
          }
        })
        .catch((err) => console.log("catch CREATE_ORDER API call", err));
    } catch (error) {
      console.log("catch CREATE_ORDER error", error);
    }
  };

  const onSubmit = (values) => {
    toggleTab("2");
    setFormValidated(true);
  };

  useEffect(() => {
    previewOrder();
  }, []);

  const onClearCode = (e) => {
    e.preventDefault();
    setCodeError("");
    setDiscountCode("");
    setAffiliateUser({});
    setSubTotal(subTotal + discount);
    setDiscount(0);
  };

  const onApplyCode = async (e) => {
    e.preventDefault();
    if (discountCode.length === 0)
      return setCodeError("Please enter discount code");
    setCodeError("");
    try {
      let uri = url.APPLY_CODE;
      let reqObj = {
        discountCode: discountCode.toUpperCase(),
      };
      await ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (response?.result?.type === Constants.SUCCESS) {
            const affiliateUser = response.result.data;
            setAffiliateUser(affiliateUser);
            const discount =
              Number(finalOrderData.totalAmount) *
              (Number(affiliateUser.discount) / 100);
            setDiscount(discount);
            setSubTotal(subTotal - discount);
            toastr.success(`${discountCode.toUpperCase()} applied`, "Success");
            return;
          }
          setCodeError(response.result.message ?? "Invalid Code");
          toastr.error(response.result.message ?? "Invalid Code", "Error");
        })
        .catch((err) => console.log("catch APPLY_CODE API call", err));
    } catch (error) {
      console.log("catch APPLY_CODE error", error);
    }
  };

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: "",
      mobileNumber: "",
      email: "",
      address: "",
      country: { name: "" },
      state: { name: "" },
      city: { name: "" },
      dob: "",
      postalCode: "",
      cityAvailable: false,
    },
    validationSchema: billingInfoSchema,
    onSubmit,
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;

  // useEffect(() => {
  //   console.log("errors", errors);
  //   console.log("values", values);
  // }, [formik]);

  return (
    <React.Fragment>
      <section
        className="section hero-section"
        style={{
          backgroundColor: "#131521",
          // backgroundImage: `url(${BackgroundImageSecondary})`,
          backgroundPosition: "top",
          backgroundRepeat: "repeat",
        }}
      >
        <Container fluid>
          <div className="checkout-tabs">
            <Row>
              <Col xl="2" sm="3">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1");
                      }}
                    >
                      <i className="bx bxs-truck d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">
                        Billing & Shipping Info
                      </p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        formik.handleSubmit();
                        if (!formValidated) return toggleTab("1");
                        setactiveTab("2");
                      }}
                    >
                      <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Confirmation</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col xl="10" sm="9">
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                    return false;
                  }}
                >
                  <Card>
                    <CardBody>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <div>
                            <CardTitle>
                              Billing & Shipping Information
                            </CardTitle>
                            <CardSubtitle className="mb-3">
                              Fill all information below
                            </CardSubtitle>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="billing-name"
                                md="2"
                                className="col-form-label"
                              >
                                Full Name
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Input
                                  id="fullName"
                                  placeholder="Enter your name"
                                  name="fullName"
                                  className="form-control"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.fullName || ""}
                                  invalid={
                                    touched.fullName && errors.fullName
                                      ? true
                                      : false
                                  }
                                />
                                {touched.fullName && errors.fullName ? (
                                  <FormFeedback type="invalid">
                                    {errors.fullName}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="billing-email-address"
                                md="2"
                                className="col-form-label"
                              >
                                Email Address
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Input
                                  id="billing-email-address"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter your email"
                                  type="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email || ""}
                                  invalid={
                                    touched.email && errors.email ? true : false
                                  }
                                />
                                {touched.email && errors.email ? (
                                  <FormFeedback type="invalid">
                                    {errors.email}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="billing-phone"
                                md="2"
                                className="col-form-label"
                              >
                                Mobile<span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md={4}>
                                <PhoneInput
                                  inputComponent={FormControl}
                                  international
                                  countryCallingCodeEditable={false}
                                  placeholder="Enter phone number"
                                  value={values.mobileNumber}
                                  defaultCountry={countryCode}
                                  onChange={(e) => {
                                    setFieldValue("mobileNumber", e);
                                  }}
                                  limitMaxLength
                                  onBlur={(e) => handleBlur("mobileNumber", e)}
                                  focusInputOnCountrySelection
                                />
                                {/* <Input
                                  id="billing-phone"
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  maxLength={10}
                                  name="mobileNumber"
                                  className="form-control"
                                  placeholder="Enter mobile number"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.mobileNumber || ""}
                                  invalid={
                                    touched.mobileNumber && errors.mobileNumber
                                      ? true
                                      : false
                                  }
                                /> */}
                                {touched.mobileNumber && errors.mobileNumber ? (
                                  <small className="text-danger">
                                    {errors.mobileNumber}
                                  </small>
                                ) : null}
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="billing-dob"
                                md="2"
                                className="col-form-label"
                              >
                                Date Of Birth
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col sm={4} md={3} lg={3} xl={2} xs={4}>
                                <Input
                                  name="dob"
                                  placeholder="Your birthdate"
                                  type="date"
                                  className="form-control"
                                  id="dob"
                                  min={Date.now()}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.dob || ""}
                                  invalid={
                                    touched.dob && errors.dob ? true : false
                                  }
                                />
                                {touched.dob && errors.dob ? (
                                  <FormFeedback type="invalid">
                                    {errors.dob}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <Row className="mb-4">
                              <Label
                                htmlFor="billing-address"
                                md="2"
                                className="col-form-label"
                              >
                                Country
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Select
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 100,
                                    }),
                                  }}
                                  name="country"
                                  id="country"
                                  placeholder="Select country"
                                  value={values.country}
                                  onChange={(e) => {
                                    setFieldValue("city", { name: "" });
                                    setFieldValue("state", { name: "" });
                                    setStates(
                                      State.getStatesOfCountry(e.isoCode)
                                    );
                                    setFieldValue("cityAvailable", false);
                                    setCities([]);
                                    if (e) {
                                      setFieldValue("country", e);
                                      return;
                                    }
                                    setFieldValue("country", { name: "" });
                                  }}
                                  options={allCountries}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.name}
                                  onBlur={() => {
                                    setFieldTouched("country", true);
                                  }}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                />
                                {touched.country && errors.country ? (
                                  <p className="small text-danger mb-0">
                                    {errors?.country?.name}
                                  </p>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="billing-address"
                                md="2"
                                className="col-form-label"
                              >
                                State
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Select
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 100,
                                    }),
                                  }}
                                  name="state"
                                  id="state"
                                  placeholder="Select State"
                                  value={values.state}
                                  onChange={(e) => {
                                    let shippingCharges =
                                      e.isoCode === "GJ" ? 50 : 100;
                                    setShipping(shippingCharges);
                                    setSubTotal(
                                      subTotal - shipping + shippingCharges
                                    );
                                    setFieldValue("city", { name: "" });
                                    if (e) {
                                      setFieldValue("state", e);
                                      let cities = City.getCitiesOfState(
                                        e.countryCode,
                                        e.isoCode
                                      );
                                      setCities(cities);
                                      setFieldValue(
                                        "cityAvailable",
                                        cities?.length > 0 ? true : false
                                      );

                                      return;
                                    }
                                    setFieldValue("state", { name: "" });
                                  }}
                                  options={statesForCountry}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.name}
                                  onBlur={() => {
                                    setFieldTouched("state", true);
                                  }}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                />
                                {touched.state && errors.state ? (
                                  <p className="small text-danger mb-0">
                                    {errors?.state?.name}
                                  </p>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                htmlFor="billing-address"
                                md="2"
                                className="col-form-label"
                              >
                                City
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Select
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 100,
                                    }),
                                  }}
                                  name="city"
                                  placeholder="Select City"
                                  id="city"
                                  value={values.city}
                                  length={4}
                                  onChange={(e) => {
                                    if (e) {
                                      setFieldValue("city", e);
                                      return;
                                    }
                                    setFieldValue("city", { name: "" });
                                  }}
                                  options={citiesForState}
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.name}
                                  onBlur={() => {
                                    setFieldTouched("city", true);
                                  }}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                />
                                {touched.city && errors.city ? (
                                  <p className="small text-danger mb-0">
                                    {errors?.city?.name}
                                  </p>
                                ) : null}
                              </Col>
                            </Row>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="billing-postalCode"
                                md="2"
                                className="col-form-label"
                              >
                                Postal Code
                                <span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Input
                                  id="postalCode"
                                  placeholder="Enter your postal code"
                                  name="postalCode"
                                  className="form-control"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.postalCode || ""}
                                  invalid={
                                    touched.postalCode && errors.postalCode
                                      ? true
                                      : false
                                  }
                                />
                                {touched.postalCode && errors.postalCode ? (
                                  <FormFeedback type="invalid">
                                    {errors.postalCode}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="billing-address"
                                md="2"
                                className="col-form-label"
                              >
                                Address<span style={{ color: "red" }}> *</span>
                              </Label>
                              <Col md="10">
                                <Input
                                  className="form-control"
                                  placeholder="Enter full address"
                                  id="billing-address"
                                  name="address"
                                  type="textarea"
                                  value={values.address}
                                  onChange={handleChange}
                                  rows="3"
                                  invalid={
                                    touched.address && errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {touched.address && errors.address ? (
                                  <FormFeedback type="invalid">
                                    {errors.address}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            {/* <FormGroup className="mb-0" row>
                              <Label
                                htmlFor="example-textarea"
                                md="2"
                                className="col-form-label"
                              >
                                Order Notes:
                              </Label>
                              <Col md="10">
                                <textarea
                                  className="form-control"
                                  id="example-textarea"
                                  rows="3"
                                  placeholder="Write some note.."
                                />
                              </Col>
                            </FormGroup> */}
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="v-pills-confir" role="tabpanel">
                          <Card className="shadow-none border mb-0">
                            <CardBody>
                              <FormGroup className="mb-0" row>
                                <Label
                                  htmlFor="billing-address"
                                  className="col-form-label col-12 card-title my-2"
                                >
                                  Apply Promocode
                                </Label>
                                <Col md="4" xl={3} className="my-2">
                                  <Input
                                    disabled={!isEmpty(affiliateUser)}
                                    className="form-control text-uppercase"
                                    placeholder="Enter code"
                                    id="billing-discountCode"
                                    name="discountCode"
                                    type="text"
                                    value={discountCode}
                                    onChange={(e) =>
                                      setDiscountCode(e.target.value.trim())
                                    }
                                    rows="3"
                                    invalid={codeError?.length > 0}
                                  />
                                  {codeError?.length > 0 ? (
                                    <FormFeedback type="invalid">
                                      {codeError}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col className="my-2">
                                  {isEmpty(affiliateUser) ? (
                                    <Button
                                      className="btn btn-success btn-md fw-bold"
                                      onClick={onApplyCode}
                                    >
                                      Apply{" "}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn btn-danger btn-md fw-bold"
                                      onClick={onClearCode}
                                    >
                                      <i className="mdi mdi-trash-can"></i>{" "}
                                    </Button>
                                  )}
                                </Col>
                              </FormGroup>
                              {!isEmpty(affiliateUser) && (
                                <Row>
                                  <Col className="fs-6 fw-bold">
                                    <i className="mdi mdi-check-decagram text-success fs-5"></i>{" "}
                                    <span className="text-success fs-5">
                                      Congratulations !
                                    </span>
                                    <br />
                                    You saved{" "}
                                    <span className="mx-1 text-success">
                                      {discount}
                                      &nbsp;
                                      {currencyString}
                                    </span>{" "}
                                    on your order.
                                  </Col>
                                </Row>
                              )}
                              <hr />
                              <CardTitle className="mb-4">
                                Order Summary
                              </CardTitle>

                              <div className="table-responsive">
                                <Table className="table align-middle mb-0 table-nowrap">
                                  <thead className="table-light">
                                    <tr>
                                      <th scope="col">Product</th>
                                      <th scope="col">Product Description</th>
                                      <th scope="col">Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isEmpty(finalOrderData?.order)
                                      ? null
                                      : finalOrderData?.order?.map(
                                          (orderitem, key) => (
                                            <tr key={"_orderSummary_" + key}>
                                              <th scope="row">
                                                <img
                                                  src={
                                                    constant.IMG_URL +
                                                      orderitem?.productImg
                                                        ?.imagePath ?? null
                                                  }
                                                  alt="product-img"
                                                  title="product-img"
                                                  className="avatar-md"
                                                />
                                              </th>
                                              <td>
                                                <h5 className="font-size-14 text-truncate">
                                                  <Link
                                                    to="/ecommerce-product-detail"
                                                    className="text-dark"
                                                  >
                                                    {orderitem.productName}{" "}
                                                  </Link>
                                                </h5>
                                                <p className="text-muted mb-0">
                                                  {currencySymbol ??
                                                    currencyString}{" "}
                                                  {orderitem?.price} x{" "}
                                                  {orderitem?.quantity}
                                                </p>
                                              </td>
                                              <td>
                                                {currencySymbol ??
                                                  currencyString}{" "}
                                                {orderitem?.productTotal}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    <tr>
                                      <td colSpan="2">
                                        <h6 className="m-0 text-end">
                                          Sub Total:
                                        </h6>
                                      </td>
                                      <td>
                                        {parseInt(finalOrderData.totalAmount)}{" "}
                                        &nbsp;
                                        <strong>{currencyString}</strong>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                    <td colSpan="3">
                                      <div className="bg-primary bg-soft p-3 rounded">
                                        <h5 className="font-size-14 text-primary mb-0">
                                          <i className="fas fa-shipping-fast me-2" />{" "}
                                          Shipping{" "}
                                          <span className="float-end">
                                            Free
                                          </span>
                                        </h5>
                                      </div>
                                    </td>
                                  </tr> */}
                                    <tr>
                                      <td colSpan="2">
                                        <h6 className="m-0 text-end">
                                          Shipping :
                                        </h6>
                                      </td>
                                      <td>
                                        +{shipping} &nbsp;
                                        <strong>{currencyString}</strong>
                                      </td>
                                    </tr>
                                    {!isEmpty(affiliateUser) && (
                                      <tr>
                                        <td colSpan="2">
                                          <h6 className="m-0 text-end  fw-bold">
                                            Discount ({affiliateUser.discount}%)
                                            :
                                          </h6>
                                        </td>
                                        <td className="fw-bold">
                                          -{discount} &nbsp;
                                          <strong>{currencyString}</strong>
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td colSpan="2">
                                        <h6 className="m-0 text-end">Total:</h6>
                                      </td>
                                      <td>
                                        {subTotal} &nbsp;
                                        <strong>{currencyString}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  <Row className="mt-4">
                    <Col sm="6">
                      {activeTab === "2" && (
                        <Button
                          to="/ecommerce-cart"
                          className="btn d-none d-sm-inline-block btn-link btn-lg"
                          onClick={(e) => {
                            toggleTab("1");
                            onClearCode(e);
                          }}
                        >
                          <i className="mdi mdi-arrow-left me-1" /> Previous{" "}
                        </Button>
                      )}
                    </Col>
                    <Col sm="6" className="pe-3">
                      <div className="text-sm-end">
                        {activeTab === "1" && (
                          <Button
                            className="btn btn-success btn-lg w-lg"
                            type="submit"
                          >
                            <i className="mdi mdi-check-decagram me-1" />
                            Confirm Order
                          </Button>
                        )}
                        {activeTab === "2" && (
                          <Button
                            className="btn btn-success btn-lg w-lg"
                            onClick={onPressPayment}
                          >
                            <i className="mdi mdi-truck-fast me-1" />
                            Proceed to Payment{" "}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ShopCheckout;
