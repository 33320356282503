import React from "react";
import { Container, Row, Col } from "reactstrap";

export const AboutUs = () => {
  document.title = "Numerolution | About Us";
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero">
        <Container>
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">ABOUT US</h1>
            <br />
            <p className="font-size-24">
              We are a group of adventurers who are highly influenced and got
              survived fantastically by numerology in life.
            </p>
            <br />
            <p className="font-size-16 text-light">
              We are a group of adventurers who are highly influenced and got
              survived fantastically by numerology in life , and we decided to
              explore enormously in the respective field in order to serve
              mankind to help them live the better life where their fait will be
              decided by their efforts and not by their luck , and making it a
              reality our Team{" "}
              <a className="text-light-green">
                <strong>NUMEROLUTION.COM</strong>
              </a>{" "}
              is working hard to bring solution of the problem of life of humans
              through numerology
            </p>
          </div>
        </Container>
      </section>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div className="row">
          <h1 className="text-mid px-2 py-4 text-center">WHY NUMEROLUTION?</h1>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object@3x-1.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Our Mission</h3>
              <p>
                We as a team are on our aim to bring happiness and property in
                humans life through numerology as birth date has an enormous
                effect in our life and by clearing negative energies which
                produces due to absence of numbers in our birth date we can live
                our life beautifully , but for doing all this we thought to
                reach as much as people and that’s why we are here , and we
                delivered our more than 10 years of experience in this field and
                prepared a platform for the delivery of our feelings towards
                making everyone’s life beautiful. We have an ultimate mission in
                our heart and that is we want a world full of confidence and joy
                where everyone would have faith in their efforts rather than to
                have faith in their luck, as we want to keep our clients’ luck
                part up to date, and through funds we receive from our client’s
                side, we want to keep those funds’ some portion to help mankind
                and needy, so we want everyone to live this life enormously.
              </p>
            </div>
          </div>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object-2@3x.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Our Vision</h3>
              <p>
                As we have one mission and one vision is to bring life of humans
                a quality of living and we are working selflessly towards to
                fulfill the same, we want to provide non expensive remedies and
                best results, we want people to take numerology in scientific
                way, as it all depends on ancient technology so come join our
                hands to deliver the best to make the human’s life an exemplary.
                so we want to built a society where every person would keep
                maintaining superb natural energies we are blessed by nature to
                us but due to our planetary actions we couldn’t receive , where
                numerolution.com and team is having vision to reach to each and
                every humans and want to identify and solve their problems
                through numerology and we also want to cater our clients in
                large numbers so we bring this web portal globally accessible ,
                we are having one ultimate vision and that is to bring happiness
                back again to human life through numerology .
              </p>
            </div>
          </div>
        </div>
        {/* team */}
        <div className="py-5">
          <Container className="px-4">
            <h1 className="text-white text-center mb-4">Our Team</h1>
            <Row className="align-items-center pt-4">
              <Col md="6" sm="8">
                <div>
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/IMG_20190427_220525_227-1-scaled.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto text-mid">
                <h2 className="text-info mb-3 mt-3">
                  Mr. Parth V Patel (B.E (Mech), M.B.A, P.G.D.H.R, Vastu
                  Consult, Numerologer)
                </h2>
                <p>
                  A mechanical engineer by education and MBA by devotion Mr.
                  parth has very spiffing experience of various corporate , he
                  as a fantastic human being helps to serve many by his kind
                  efforts experienced power of birth date and he get cured by
                  one numeric specialist then his life started observing a huge
                  change , then he decided to pursue mastery in order to help
                  many more people who are talented enough to claim success in
                  any field but under the influence by their birth date – they
                  couldn’t . Mr.parth has changed thousands of life and would
                  continue to do so. Also Mr.Parth is certified numero
                  specialist who is powerful enough to get the clients life
                  through numerology aspect, Mr.Parth is also expert in
                  signature numerology, Home Vaastu correction, smooth going
                  foreign settlement, career counseling and many more numerology
                  services, Mr.Parth is chasing his dreams of spreading joy and
                  happiness by providing solutions to problems through
                  numerology and will also continue doing so.
                </p>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5">
              <Col md="5">
                <h2 className="text-info mb-3">
                  Mr. Jay Mistry (B.E (Electric), PGDM, AMA-CSUSB U.S.A)
                </h2>
                <p>
                  Being an electrical engineer and PGDM in corporate sales and
                  marketing (AMA-CSUSB USA) Mr. Jay is having rich experience in
                  delivering the best things to best class of people . Mr.Jay
                  was having proper knowledge about his field and was doing
                  successful business but he couldn’t save money as the finances
                  were drying out day by day , but Mr.Jay was not giving up , so
                  suddenly one numerolo specialist come and suggest him some
                  remedies of numerology and Mr.Jay decided to adopt them and ,
                  His problems become no more in near future of this incidents ,
                  and right now he is leading successful life , but being a
                  helpful person he decided to help others to bring them out of
                  all problems through numerology and from that day Together
                  with Mr. Parth , Mr. Jay has been chasing a dream to change
                  the life of millions through numerolution successfully
                </p>
              </Col>
              <Col md="6" sm="8" className="ms-md-auto">
                <div className="mt-4 me-md-0">
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2022/02/1644135671487-1024x682.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
