import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Container, Spinner } from "reactstrap";
import * as Yup from "yup";

//Import Image
import BackgroundImageSecondary from "assets/images/crypto/bg-ico-hero.jpg";
import { ApiServices } from "service/apiServices.js";
import * as url from "service/url_helper";
import service from "../../service/constant.js";

const GetPdf = (props) => {
  //meta title
  document.title = "PDF | Numerolution";
  const [pdfType, setPdfType] = useState("");
  const [pdfDownload, setPdfDownload] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const verifyPaymentAndDownloadPDF = () => {
    let orderId
    if(props.location.search){
      orderId = new URLSearchParams(props.location.search).get("token")
    } else {
      let ids = JSON.stringify(props.match.params).split("/");
      orderId = ids[2];
      let orderToken = ids[3].slice(0, -2);
    }
    let orderObj = {
      order_id: orderId,
    };
    let uri = url.GET_PAYMENT_FOR_PDF;
    ApiServices.callServicePostWithBodyData(uri, orderObj).then(
      async (response) => {
        if (response.result.type === "success") {
          setPdfType(true);
          let FinalPDFPath =
            service.IMG_URL + "/" + response.result.data.pdfPath;
          setPdfDownload(FinalPDFPath);
          //Automatic PDF Download
          setTimeout(() => {
            onButtonClick(FinalPDFPath);
            setButtonDisabled(false);
          }, 2000);
        } else if (response.result.type === "null") {
          setPdfType(null);
        } else {
          setPdfType(false);
        }
      }
    );
  };
  useEffect(() => {
    // setTimeout(() => {
    verifyPaymentAndDownloadPDF();
    // }, 2000);
    // console.log("props.match.params.order_token",props.match.params.order_token)
  }, []);
  const onButtonClick = (pdfDownload) => {
    // using Java Script method to get PDF file
    fetch(pdfDownload).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Numerology-Report.pdf";
        alink.click();
      });
    });
  };
  return (
    <React.Fragment>
      <section
        className="section hero-section"
        style={{
          // backgroundColor: "#131521",
          backgroundImage: `url(${BackgroundImageSecondary})`,
          backgroundPosition: "top",
          backgroundRepeat: "repeat",
          // paddingTop : '12rem'
        }}
      >
        <Container>
          <div
            hidden={pdfType === false || pdfType === null || pdfType === true}
            className="align-items-center px-4 text-white text-center"
          >
            <h2
              className="text-light-green d-flex align-items-center justify-content-center"
              style={{ textAlign: "left" }}
            >
              <i
                className="bx bx-loader-circle bx-spin me-2 text-light-green"
                style={{ fontSize: "4rem" }}
              />
              <div>
                Checking Payment Status...
                <br />
                <p className="text-white mt-2 fs-5 mb-0">
                  This may take a while.
                </p>
              </div>
            </h2>
            <br />
            <br />
            <br />
            <Button
              size="md"
              color="success"
              className="w-md"
              outline
              onClick={verifyPaymentAndDownloadPDF}
            >
              Try again
            </Button>
          </div>
          <div
            hidden={pdfType === false || pdfType === null || pdfType === ""}
            className="align-items-center px-4 text-white text-center"
          >
            <h2 className="text-light-green d-flex align-items-center justify-content-center mb-5">
              <i
                className="mdi mdi-checkbox-marked-circle-outline me-2 text-light-green"
                style={{ fontSize: "4rem" }}
              />
              Payment Successful
            </h2>
            <br />
            {buttonDisabled ? (
              <h4 className="text-light-green">
                Be Hold ! Your report is being downloaded !!!
              </h4>
            ) : (
              <>
                <h4 className="text-light-green d-flex align-items-center justify-content-center">
                  Your Numerology report is ready to be downloaded !!!
                </h4>
              </>
            )}
            <br />
            <br />
            {buttonDisabled ? (
              <Button size="lg" color="success" className="w-lg" outline>
                <Spinner className="me-2" size={"sm"} color="success" />
                Loading...
              </Button>
            ) : (
              <Button
                size="lg"
                color="success"
                className="w-lg"
                outline
                onClick={() => onButtonClick(pdfDownload)}
              >
                Download
              </Button>
            )}
          </div>
          <div
            hidden={pdfType === true || pdfType === null || pdfType === ""}
            className="align-items-center mt-5  px-4 text-white text-left"
          >
            <h3 className="text-danger d-flex align-items-center mb-2">
              <i
                className="mdi mdi-alert-remove-outline me-2"
                style={{ fontSize: "4rem" }}
              />
              Your transaction has been failed.
            </h3>
            <p className="text-white fs-5 mb-0">
              Oops !!! Something went wrong.
              <br />
              If the amount is deducted from your account, please wait till 3-4
              business days to get refunded.
              <br />
              For further queries please contact your bank.
            </p>
          </div>
          <div
            hidden={pdfType === true || pdfType === false || pdfType === ""}
            className="align-items-center mt-5  px-4 text-white text-left"
          >
            <h3 className="text-warning d-flex align-items-center mb-0">
              <i
                className="mdi mdi-alert-circle-outline me-2"
                style={{ fontSize: "4rem" }}
              />
              <div>
                Your Report is already downloaded
                <br />
                <p className="text-white mt-2 fs-5 mb-0">
                  Our system has recognized that you have already downloaded the
                  report of Numerolution.
                </p>
              </div>
            </h3>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default GetPdf;
