import React from "react";
import { Container, Row, Col } from "reactstrap";

export const CareerCounseling = () => {
  document.title =
    "Numerolution | Career Counseling - Business and job recommendation";
  return (
    <React.Fragment>
      <section
        style={{
          background:
            "url(https://www.numerolution.com/wp-content/uploads/2022/08/1408399789000-captain-nightlanding.jpg)",
        }}
        className="bg-ico-hero"
      >
        <Container
          className="section hero-section max-full"
          style={{
            backdropFilter: "blur(2px) brightness(0.3)",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">
              CAREER GUIDANCE / JOB AND BUSINESS RECOMMENDATION
            </h1>
            <br />
            <p className="font-size-18">
              To be in right career is not matter of luck now because we are
              here and all set to help you in choosing right career for your
              future , You just need to contact our book appointment section to
              have personally live interaction with best career counsellor in
              the world Mr.Parth Patel .
            </p>
          </div>
        </Container>
      </section>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div
          className="row text-center"
          style={{ marginTop: "-15%", marginBottom: "5%" }}
        >
          <img
            style={{ boxShadow: "0px 0px 10px 5px #ffc8c8" }}
            className="rounded-5 p-0"
            src="https://www.numerolution.com/wp-content/uploads/2021/05/iStock_000019157558Small-1.jpg"
          />
        </div>
        <div className="row">
          <h1 className="text-light-green px-2 py-4 text-center">
            UNDERSTAND & IMPROVE
          </h1>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object@3x.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Eliminating Confusion About Career</h3>
              <p>
                This is the important and most common problem to choose the
                right career, because right career can bring you to the top and
                wrong can bring you to the bottom, so better we choose our
                career according to the numerology method based on your birth
                date, so when you have confusion whether to choose 10th or 12th
                or the field of technical, sales, marketing, production or
                anything else then don’t be worry as team numerolution is all
                set to welcome you to Largest Career Counselling platform and
                you select your perfect career.
              </p>
            </div>
          </div>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/06/vector-smart-object-2@3x-1.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">Global Education Consultancy</h3>
              <p>
                As Many of students and parents remain worried about their
                children’s future that how the particular field would be for
                their loving ones as many examples are there in our society who
                reflects us the importance of choosing right career , example
                like Boman irani (Bollywood actor) once was a waiter ,
                Rajnikant(south Indian film industry legend)once was a bus
                conductor and right now these guys are having millions while
                they were earning nothing before in their early life so their
                respective field of work blessed them the huge success We know
                very well that how your birth date can be a game changer for
                your career and how it determines
              </p>
            </div>
          </div>
        </div>
        {/* team */}
        <div className="py-5">
          <Container className="px-4">
            <h1 className="text-light-green text-center mb-4">
              UNDERSTANDING CAREER COUNSELING
            </h1>
            <Row className="align-items-center pt-4">
              <Col md="6" sm="8">
                <div>
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/5ef47a3d2eb2a-1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto text-mid">
                <h2 className="text-info mb-3 mt-3">
                  Career Ccounselling For Students
                </h2>
                <p>
                  What you become or which field would bring great fortune for
                  you, so guys don’t worry and get guidance from the best career
                  counselors in India famous numero specialist once before you
                  proceed further.
                  <br />
                  <br />
                  You can get in touch via ZOOM OR SKYPE with our popular
                  numerologist Mr.Parthpatel and he would guide you to success.
                  So book an appointment today to get relax about your career
                  and book an appointment right now.
                </p>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5">
              <Col md="5">
                <h2 className="text-info mb-3">Global Education Consultancy</h2>
                <p>
                  We are living in the world of magic, because when our great
                  cricketers who are earning millions right now would have been
                  earning less than 20000 a month if they would have preferred
                  to choose laundry business instead of playing cricket. in
                  short it is necessary to be into right profession in the right
                  time, so don’t waste your time in wrong profession because it
                  is preventing you to be hero and it will limit yourself to
                  zero so guys don’t be worry as we are here to help you out to
                  choose perfect profession for you and Mr.parth patel would
                  guide you for the same, you can book their appointment right
                  now and get relaxed about your career right now
                </p>
              </Col>
              <Col md="6" sm="8" className="ms-md-auto">
                <div className="mt-4 me-md-0">
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/shutterstock_225293632-1-1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
            </Row>
            <h1 className="text-light-green text-center mb-4 mt-5">
              BENEFITS OF CAREER COUNSELING AND RIGHT PROFESSION SELECTION
              THROUGH NUMEROLOGY.
            </h1>
            <div className="px-lg-5 py-lg-4">
              <ul style={{ listStyle: "none" }} className="px-2">
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get experts advice on which career selection would bring great
                  success and fortune for your life.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get solution to which profession would be perfect according to
                  your birth date for you.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Most effective solution especially after 10th and 12th
                  standard students.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get online solutions to your career related issues through
                  Skype with our numerology expert Mr.Parth Patel
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Over 1200 successful career advices given across globe by
                  numerolution.com team through numerology over online
                  appointment.
                </li>
              </ul>
            </div>
            <div className="m-lg-4 p-lg-4">
              <div className="border font-lg-size-16 font-monospace border-2 border-success p-lg-3 p-2 rounded text-secondary bg-success text-white">
                <span>
                  “So book an appointment today to get relax about your career
                  and book an appointment right now.”
                </span>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
