import { isEmpty } from "lodash";
import { Constants } from "./constant";
import Compressor from "compressorjs";

export const isUserLoggedIn = () => {
  let token = getSecureToken(Constants.KEY_USER_TOKEN);
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  if (isEmpty(token) && isEmpty(userData)) {
    return false;
  } else {
    return true;
  }
};

export const setSecureToken = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getSecureToken = (key) => {
  const data = localStorage.getItem(key);
  if (data !== null) {
    return JSON.parse(data);
  } else {
    return false;
  }
};
export const getCompressedImages = (rawImgs) => {
  return new Promise((resolve, reject) => {
    if (rawImgs?.length > 0) {
      let updatedArr = [];
      rawImgs.map((image, index) => {
        new Compressor(image, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          maxWidth: 1000,
          maxHeight: 1000,
          strict: true,
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            updatedArr.push(compressedResult);
            if (updatedArr.length === rawImgs.length) {
              return resolve(updatedArr);
            }
          },
          error: (error) => {
            return reject(error);
          },
        });
      });
    }
  });
};
