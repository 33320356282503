import React from "react";
import { Container, Row, Col } from "reactstrap";

export const LoveRelationship = () => {
  document.title = "Numerolution | Love And Relationship Solutions";
  return (
    <React.Fragment>
      <section
        style={{
          background:
            "url(https://www.numerolution.com/wp-content/uploads/2022/08/love-heart-space-stars-galaxy-hd-wallpaper-2b769c3df311cf692594abd9dd2c1cc0-e1661100440632.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="bg-ico-hero"
      >
        <Container
          className="section hero-section max-full"
          style={{
            backdropFilter: "brightness(0.3)",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <div className="align-items-center px-4 text-white text-center">
            <h1 className="text-light-green">LOVE PROBLEM AND SOLUTION</h1>
            <br />
            <p className="font-size-18">
              The most important factor about your life is your love life
              because little conflicts are part of your life but those conflicts
              should be limited to some extent only and conflicts may be part of
              life but it should not be heart of life, but only wearer knows the
              pain where the shoe pinches, just like that we also let the
              conflict win over relation which is wrong, however don’t be sad as
              we are here and we would provide the best solution available for
              your life.
            </p>
          </div>
        </Container>
      </section>
      <div
        className="font-size-14"
        style={{
          padding: "10%",
          paddingTop: "5%",
          paddingBottom: "5%",
          backgroundColor: "#131521",
          color: "#c4c4c4",
        }}
      >
        <div className="row">
          <h1 className="text-light-green px-2 py-4 text-center">
            UNDERSTAND & IMPROVE
          </h1>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/07/vector-smart-object@3x.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">When Love Has To Win Over Hate</h3>
              <p>
                Are you facing problems with your spouse? Are you happy with
                your married life? If yes, then it’s spiffing and we wish you
                will be forever! But, what if the answer is NO then what can be
                the solution? Love is caring, which can’t be expressed in a
                world because of it indescribable feeling. This feeling makes
                compelled to you to take care of you partner and devote life for
                beloved one. But over a time of love relation, this lovely
                relation go through some issue. We wish that you at list contact
                us for once to solve your issues with numerology, we are going
                to assess your birth date and would do remedies and we are sure
                that we would reach our goal to spread happiness in everyone’s
                life including yours.
              </p>
            </div>
          </div>
          <div className="col-md-6 row px-4 py-2">
            <div className="col-lg-2 col-sm-12">
              <img
                height="50"
                src="https://www.numerolution.com/wp-content/uploads/2020/06/vector-smart-object-2@3x-1.png"
              />
            </div>
            <div className="col-lg-10 col-sm-12">
              <h3 className="text-info">
                Bring Your Problem An Evergreen Solution
              </h3>
              <p>
                As there is a big difference of problem solving of Husband Wife
                Dispute Relationship Problem Solution. Women will discuss her
                problem to friends and family and try to find so many solutions
                of her problem and takes much time to solve it. On the other
                hand a man is solitary thinkers who do not wish to discuss his
                problem anyone and on own finds the solutions. A great
                astrologer understands these all differences and extracts the
                solvable technique according to the nature and behavior and
                numerology.
              </p>
            </div>
          </div>
        </div>
        {/* team */}
        <div className="py-5">
          <Container className="px-4">
            <h1 className="text-light-green text-center mb-4">
              UNDERSTANDING LOVE AND RELATIONSHIP SOLUTIONS
            </h1>
            <Row className="align-items-center pt-4">
              <Col md="6" sm="8">
                <div>
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2020/06/9029954_f1024-1.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto text-mid">
                <h2 className="text-info mb-3 mt-3">We're here for you!</h2>
                <p>
                  If you are going through such situation then be relaxed we
                  would keep everything in very confidential and we would bring
                  solution to your relationship problems just don’t hesitate to
                  book an appointment with our popular numero specialist
                  Mr.Parthpatel as your confidential information will be
                  secured, as we are here to help you out in your trouble time ,
                  we would provide you accurate solutions as per your birth date
                  so that each and every of your problems would be no more ,
                  just book our famous numero specialist Mr.Parth Patel’s
                  appointment to know more about how you can solve your love and
                  relationship problems in your life through numerology.
                </p>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5">
              <Col md="5">
                <h2 className="text-info mb-3">Relationship solution</h2>
                <p>
                  When you find difficulty regarding each and every of your try
                  or resources to bring back your ruined love life back on track
                  then do not hesitate to talk at list for once with team
                  numerolution as numerology is having great impact on your life
                  and we would definitely provide solution to all your problems
                  and we have proud and no doubt on our knowledge and the way we
                  have solved problems of many so far in the life of many , so
                  lets bring solution to our life’s problems by proper
                  understanding the nature from the core and solve the Mistry
                  with backing of numerology by booking an appointment with our
                  famous numero specialist Mr.Parth Patel .
                </p>
              </Col>
              <Col md="6" sm="8" className="ms-md-auto">
                <div className="mt-4 me-md-0">
                  <img
                    src="https://www.numerolution.com/wp-content/uploads/2022/08/wallpapersden.com_hd-artistic-love-galaxy-art_ws.jpg"
                    alt=""
                    className="img-fluid mx-auto d-block rounded-4 w-8 shadow-lg"
                  />
                </div>
              </Col>
            </Row>
            <h1 className="text-light-green text-center mb-4 mt-5">
              BENEFITS OF LOVE AND RELATIONSHIP SOLUTIONS.
            </h1>
            <div className="px-lg-5 py-lg-4">
              <ul style={{ listStyle: "none" }} className="px-2">
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get solutions of all your love problem solution on phone and
                  online. we have online love problem solution astrologer and
                  numerologer person.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Get yourself free from love tensions, love pain , financial
                  problems affecting love life and separation anxiety by talking
                  our numerology expert Mr.Parth patel’s guidance.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Have smooth going relationship solution with your parents or
                  wife through Vaastu and numerology by taking our expert’s
                  advice. We have well known online love problem solution
                  astrologer and numerologer person.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  Your confidential data security guarantee.
                </li>
                <li className="p-2">
                  <i className="mdi mdi-information text-bg-success rounded px-1 text-white mx-2" />
                  One stop solution to love relation problem solutions quickly
                  and more effectively through numerology by booking appointment
                  of our numerology specialist Mr.Parth Patel.
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
