import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { Constants } from "helpers/constant";
import { getSecureToken } from "helpers/utils";
import { toggleLeftmenu } from "../../store/actions";

const Navbar = (props) => {
  const [dashboard, setdashboard] = useState(false);
  const [postJob, setPostJob] = useState(false);
  const [property, setProperty] = useState(false);
  const [ghar, setGhar] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [room, setRoom] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].hash.replace("#", "")) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      setdashboard(!dashboard);
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                    to="/home"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Dashboard")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                  {/* <div
                    className={classname("dropdown-menu", { show: dashboard })}
                  >
                    <Link to="/dashboard" className="dropdown-item">
                      {props.t("Default")}
                    </Link>
                  </div> */}
                </li>
                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      setPostJob(!postJob);
                    }}
                    to="/"
                  >
                    <i className="fas fa-suitcase me-2"></i>
                    {props.t("Job")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: postJob })}
                  >
                    <Link 
                    onClick={()=>{props.toggleLeftmenu(!props.leftMenu)}}
                    to={props?.profileData?.job?.isModuleEnabled ? "/post-job" : "/company-registration"} 
                    className="dropdown-item">
                      {props.t("Post Job")}
                    </Link>
                    <Link 
                    onClick={()=>{props.toggleLeftmenu(!props.leftMenu)}} 
                    to="/job-list" 
                    className="dropdown-item">
                      {props.t("View Posted Jobs")}
                    </Link>
                  </div>
                </li> */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      setGhar(!ghar);
                    }}
                    to="/"
                  >
                    <i className="bx bx-buildings me-2"></i>
                    {props.t("Products")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: ghar })}>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setProperty(!property);
                        }}
                      >
                        {props.t("Property")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: property,
                        })}
                      >
                        <Link
                          onClick={() => {
                            props.toggleLeftmenu(!props.leftMenu);
                          }}
                          to={
                            props?.userData?.isDocumentUploaded
                              ? "/property/add"
                              : "/property/registration"
                          }
                          className="dropdown-item"
                        >
                          {props.t("Post Property")}
                        </Link>
                        <Link
                          onClick={() => {
                            props.toggleLeftmenu(!props.leftMenu);
                          }}
                          to="/property/list"
                          className="dropdown-item"
                        >
                          {props.t("View Your Properties")}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setRoom(!room);
                        }}
                      >
                        {props.t("Room")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: room })}
                      >
                        <Link
                          onClick={() => {
                            props.toggleLeftmenu(!props.leftMenu);
                          }}
                          to="/room/bulk-upload"
                          className="dropdown-item"
                        >
                          {props.t("Add Bulk Rooms")}
                        </Link>
                        <Link
                          onClick={() => {
                            props.toggleLeftmenu(!props.leftMenu);
                          }}
                          to="/room/list"
                          className="dropdown-item"
                        >
                          {props.t("View Rooms")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  profileData: PropTypes.any,
  userData: PropTypes.any,
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar))
);
