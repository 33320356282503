import React from "react";
import "./privacypage.css";
import { Container, Row } from "reactstrap";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div
        style={{
          top: "0",
          height: "190px",
          background: "linear-gradient(180deg, black, #f8f8fb)",
        }}
        className="w-100 position-absolute bg-black"
      />
      <div className="page-content" style={{ marginTop: "5%" }}>
        <Container fluid>
          <Row>
            <p class="p1">
              This Privacy Policy governs the manner in which Numerolution
              collects, uses, maintains and discloses information collected from
              users (each, a “User”) of the&nbsp;
              <a href="https://www.numerolution.com/">
                <span class="s1">https://www.numerolution.com/</span>
              </a>{" "}
              website (“Site”). This privacy policy applies to the Site and all
              products and services offered by Numerolution.
            </p>
            <p class="p2">
              <strong>Personal identification information</strong>
            </p>
            <p class="p3">
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, place an order, subscribe to the
              newsletter, respond to a survey, fill out a form, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, phone number,
              credit card information, social security number. Users may,
              however, visit our Site anonymously. We will collect personal
              identification information from Users only if they voluntarily
              submit such information to us. Users can always refuse to supply
              personally identification information, except that it may prevent
              them from engaging in certain Site related activities.
            </p>
            <p class="p2">
              <strong>Non-personal identification information</strong>
            </p>
            <p class="p3">
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>
            <p class="p2">
              <strong>Web browser cookies</strong>
            </p>
            <p class="p3">
              Our Site may use “cookies” to enhance User experience. User’s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>
            <p class="p2">
              <strong>How we use collected information</strong>
            </p>
            <p class="p3">
              Numerolution may collect and use Users personal information for
              the following purposes:
            </p>
            <ul class="ul1">
              <li class="li4">
                <span class="s3">
                  <em>–To improve customer service</em>
                  <br />
                  Information you provide helps us respond to your customer
                  service requests and support needs more efficiently.
                </span>
              </li>
              <li class="li5">
                <span class="s3">
                  <em>– To personalize user experience</em>
                  <br />
                  We may use information in the aggregate to understand how our
                  Users as a group use the services and resources provided on
                  our Site.
                </span>
              </li>
              <li class="li5">
                <span class="s3">
                  <em>– To improve our Site</em>
                  <br />
                  We may use feedback you provide to improve our products and
                  services.
                </span>
              </li>
              <li class="li5">
                <span class="s3">
                  <em>– To process payments</em>
                  <br />
                  We may use the information Users provide about themselves when
                  placing an order only to provide service to that order. We do
                  not share this information with outside parties except to the
                  extent necessary to provide the service.
                </span>
              </li>
              <li class="li5">
                <span class="s3">
                  <em>– To generate report on user numerology</em>
                  <br />
                  To send Users information they agreed to receive detail
                  content about their numerology. The content is in a pdf
                  format. To generate that we ask user to enter their name,
                  email id, birthdate, phone number and we ask the preferred
                  language to download that pdf.
                </span>
              </li>
              <li class="li5">
                <span class="s3">
                  <em>– To send periodic emails</em>
                  <br />
                  We may use the email address to send User information and
                  updates pertaining to their order. It may also be used to
                  respond to their inquiries, questions, and/or other requests.
                  If User decides to opt-in to our mailing list, they will
                  receive emails that may include company news, updates, related
                  product or service information, etc. If at any time the User
                  would like to unsubscribe from receiving future emails, we
                  include detailed unsubscribe instructions at the bottom of
                  each email or User may contact us via our Site.
                </span>
              </li>
              <li class="li5">
                <span class="s3">
                  <em>– To generate report </em>
                  <br />
                  We may take
                </span>
                <span class="s4">
                  <span class="Apple-converted-space">&nbsp;</span>
                </span>
              </li>
            </ul>
            <p class="p6">
              We may use the email address to send User information and updates
              pertaining to their order. It may also be used to respond to their
              inquiries, questions, and/or other requests. If User decides to
              opt-in to our mailing list, they will receive emails that may
              include company news, updates, related product or service
              information, etc. If at any time the User would like to
              unsubscribe from receiving future emails, we include detailed
              unsubscribe instructions at the bottom of each email or User may
              contact us via our Site.
            </p>
            <p class="p7">
              <br />
            </p>
            <p class="p2">
              <strong>How we protect your information</strong>
            </p>
            <p class="p3">
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
            <p class="p3">
              Sensitive and private data exchange between the Site and its Users
              happens over a SSL secured communication channel and is encrypted
              and protected with digital signatures. Our Site is also in
              compliance with PCI vulnerability standards in order to create as
              secure of an environment as possible for Users.
            </p>
            <p class="p2">
              <strong>Sharing your personal information</strong>
            </p>
            <p class="p3">
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above.
            </p>
            <p class="p2">
              <strong>Changes to this privacy policy</strong>
            </p>
            <p class="p3">
              Numerolution has the discretion to update this privacy policy at
              any time. When we do, we will revise the updated date at the
              bottom of this page. We encourage Users to frequently check this
              page for any changes to stay informed about how we are helping to
              protect the personal information we collect. You acknowledge and
              agree that it is your responsibility to review this privacy policy
              periodically and become aware of modifications.
            </p>
            <p class="p2">
              <strong>Your acceptance of these terms</strong>
            </p>
            <p class="p1">
              By using this Site, you signify your acceptance of this policy
              and&nbsp;
              <a href="https://www.pandit.com/terms-service-policy-agreement/">
                <span class="s5">terms of service</span>
              </a>
              . If you do not agree to this policy, please do not use our Site.
              Your continued use of the Site following the posting of changes to
              this policy will be deemed your acceptance of those changes.
            </p>
            <p class="p2">
              <strong>Contacting us</strong>
            </p>
            <p class="p3">
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at:
            </p>
            <p class="p8">
              <strong>Parth Patel (Owner)</strong>
            </p>
            <p class="p8">
              <strong>Numerolution</strong>
            </p>
            <p class="p8">
              908, Ganesh Glory,
              <span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p class="p8">
              Gota Ahmedabad,<span class="Apple-converted-space">&nbsp;</span>
            </p>
            <p class="p8">Gujarat, India 382481.</p>
            <p class="p9">
              <br />
            </p>
            <p class="p8">+917043329693</p>
            <p class="p10">
              <span class="s6">
                <a href="mailto:solution@numerolution.com">
                  solution@numerolution.com
                </a>
              </span>
            </p>
            <p class="p3">This document was last updated on Jan 01, 2023</p>

            <br />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
