import { Constants } from "../helpers/constant";
import constant from "./constant";
import { getSecureToken } from "../helpers/utils";

/***************************** API URL **************************/
// const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl = constant.API_URL;
/******************************************************************/

export const ApiServices = {
  callServicePostWithBodyData: async (URL, apiBody) => {
    return new Promise(async (resolve, reject) => {
      let token = await getSecureToken(Constants.KEY_USER_TOKEN);
      let currencyCode = sessionStorage.getItem("currencyCode") ?? "USD";

      await fetch(apiUrl + URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          currencyCode: currencyCode,
        },
        body: JSON.stringify(apiBody),
      })
        .then((responseData) => responseData.json())
        .then((response, error) => {
          if (response) {
            return resolve(response);
          } else {
            return reject(error);
          }
        })
        .catch((error) => {
          console.log("error", apiUrl + URL, ">>>>>>>", error);

          if (error.message == Constants.ERROR_COMETCHAT_LOGOUT) {
            return reject(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
          } else if (error.message == Constants.ERROR_NETWORK_REQUEST_FAILED) {
            return reject(Constants.NO_INTERNET);
          } else if (typeof error == String(undefined)) {
            return reject("");
          } else {
            return reject(error);
          }
        });
    });
  },
  callServicePutWithBodyData: async (URL, apiBody) => {
    return new Promise(async (resolve, reject) => {
      let token = await getSecureToken(Constants.KEY_USER_TOKEN);
      let currencyCode = sessionStorage.getItem("currencyCode") ?? "USD";

      await fetch(apiUrl + URL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          currencyCode: currencyCode,
        },
        body: JSON.stringify(apiBody),
      })
        .then((responseData) => responseData.json())
        .then((response, error) => {
          if (response) {
            return resolve(response);
          } else {
            return reject(error);
          }
        })
        .catch((error) => {
          console.log("error", apiUrl + URL, ">>>>>>>", error);

          if (error.message == Constants.ERROR_COMETCHAT_LOGOUT) {
            return reject(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
          } else if (error.message == Constants.ERROR_NETWORK_REQUEST_FAILED) {
            return reject(Constants.NO_INTERNET);
          } else if (typeof error == String(undefined)) {
            return reject("");
          } else {
            return reject(error);
          }
        });
    });
  },
  callServiceGet: async (URL) => {
    return new Promise(async (resolve, reject) => {
      let token = await getSecureToken(Constants.KEY_USER_TOKEN);
      let currencyCode = sessionStorage.getItem("currencyCode") ?? "USD";

      await fetch(apiUrl + URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          currencyCode: currencyCode,
        },
      })
        .then((responseData) => responseData.json())
        .then((response, error) => {
          if (response) {
            return resolve(response);
          } else {
            return reject(error);
          }
        })
        .catch((error) => {
          console.log("error", apiUrl + URL, ">>>>>>>", error);

          if (error.message == Constants.ERROR_COMETCHAT_LOGOUT) {
            return reject(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
          } else if (error.message == Constants.ERROR_NETWORK_REQUEST_FAILED) {
            return reject(Constants.NO_INTERNET);
          } else if (typeof error == String(undefined)) {
            return reject("");
          } else {
            return reject(error);
          }
        });
    });
  },
  callServicePostWithFormData: async (URL, apiBody) => {
    return new Promise(async (resolve, reject) => {
      let token = await getSecureToken(Constants.KEY_USER_TOKEN);
      let currencyCode = sessionStorage.getItem("currencyCode") ?? "USD";

      await fetch(apiUrl + URL, {
        method: "POST",
        headers: {
          Authorization: token,
          currencyCode: currencyCode,
        },
        body: apiBody,
      })
        .then((responseData) => responseData.json())
        .then((response, error) => {
          if (response) {
            return resolve(response);
          } else {
            return reject(error);
          }
        })
        .catch((error) => {
          console.log("error", error);
          console.log("error", apiUrl + URL, ">>>>>>>", error);

          if (error.message == Constants.ERROR_COMETCHAT_LOGOUT) {
            return reject(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
          } else if (error.message == Constants.ERROR_NETWORK_REQUEST_FAILED) {
            return reject(Constants.NO_INTERNET);
          } else if (typeof error == String(undefined)) {
            return reject("");
          } else {
            return reject(error);
          }
        });
    });
  },
  callServicePutWithFormData: async (URL, apiBody) => {
    return new Promise(async (resolve, reject) => {
      let token = await getSecureToken(Constants.KEY_USER_TOKEN);
      let currencyCode = sessionStorage.getItem("currencyCode") ?? "USD";

      await fetch(apiUrl + URL, {
        method: "PUT",
        headers: {
          Authorization: token,
          currencyCode: currencyCode,
        },
        body: apiBody,
      })
        .then((responseData) => responseData.json())
        .then((response, error) => {
          if (response) {
            return resolve(response);
          } else {
            return reject(error);
          }
        })
        .catch((error) => {
          console.log("error", apiUrl + URL, ">>>>>>>", error);

          if (error.message == Constants.ERROR_COMETCHAT_LOGOUT) {
            return reject(Constants.ERROR_MESSAGE_COMETCHAT_LOGOUT);
          } else if (error.message == Constants.ERROR_NETWORK_REQUEST_FAILED) {
            return reject(Constants.NO_INTERNET);
          } else if (typeof error == String(undefined)) {
            return reject("");
          } else {
            return reject(error);
          }
        });
    });
  },
};
