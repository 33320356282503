import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Dashboard,
  ecommerce,
});

export default rootReducer;
