import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { ApiServices } from "service/apiServices";
import * as url from "service/url_helper";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);
const currencyCodes = ["INR", "AUD", "CAD", "USD", "GBP", "EUR"];
import countries from "helpers/country-city-state/country";
const App = (props) => {
  const setCurrency = async () => {
    try {
      // sessionStorage.setItem("currencyCode", "INR");
      // sessionStorage.setItem("currencySymbol", "₹");
      // sessionStorage.setItem("currencyString", "INR(₹)");
      // sessionStorage.setItem("phoneCode", "+91");
      // sessionStorage.setItem("countryCode", "IN");
      // return;
      localStorage.clear();
      let uri = url.USER_CURRENCY;
      await ApiServices.callServicePostWithBodyData(uri, {})
        .then((response) => {
          if (response?.result?.type === "success") {
            let data = response?.result?.data;
            let phoneCode =
              countries.find((country) => country.iso2 === data.countryCode)
                ?.phone_code ?? "+1";
            let validatedPhoneCode =
              phoneCode.charAt(0) === "+" ? phoneCode : "+" + phoneCode;
            sessionStorage.setItem("countryCode", data.countryCode);
            if (data?.isEU) {
              sessionStorage.setItem("currencyCode", "EUR");
              sessionStorage.setItem("currencySymbol", "€");
              sessionStorage.setItem("currencyString", "EUR(€)");
              sessionStorage.setItem("phoneCode", validatedPhoneCode);
              return;
            }
            if (currencyCodes.includes(data?.countryCurrency?.code)) {
              sessionStorage.setItem(
                "currencyCode",
                data?.countryCurrency?.code
              );
              sessionStorage.setItem(
                "currencySymbol",
                data?.countryCurrency?.symbol
              );
              sessionStorage.setItem(
                "currencyString",
                `${data?.countryCurrency?.code}(${data?.countryCurrency?.symbol})`
              );
              sessionStorage.setItem("phoneCode", validatedPhoneCode);
              return;
            }
            sessionStorage.setItem("currencyCode", "USD");
            sessionStorage.setItem("currencySymbol", "$");
            sessionStorage.setItem("currencyString", "USD($)");
            sessionStorage.setItem("phoneCode", validatedPhoneCode);
          }
        })
        .catch((error) => {
          console.log("catch setCurrency", error);
        });
    } catch (error) {
      console.log("catch ipInfo==>", error);
    }
  };
  useEffect(() => {
    let currencyCode = sessionStorage.getItem("currencyCode");
    if (currencyCode && currencyCodes.includes(currencyCode)) return;
    setCurrency();
  }, []);

  return (
    <React.Fragment>
      <Router basename="/#">
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={HorizontalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
